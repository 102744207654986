// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//test
// clientCode: 'TEST_CLIENT',

//desarrollo
// clientCode: 'ARN_FB_CLIENT',
//production: true,


// VERSIÓN FACEBANK DESARROLLO         urlApi: 'http://165.227.199.158:8080/',

const workflow = 'ACCOUNT_OPENING'; // Esté siempre será el por defecto
const workflow2 = 'DOC_SUPPORT';

export const environment = {
    production: false,
    procedureMail: 'Apertura de Cuenta',
    urlFront: 'localhost:4200',
    workflow: workflow,
    workflow2: workflow2,
    defaultStorefrondRoute: 'public/' + workflow + '/stages/LOGIN_' + workflow + '/steps/LOGIN_' + workflow,
    defaultInitRoute: 'public/' + workflow + '/stages/LOGIN_' + workflow + '/steps/LOGIN_' + workflow,
    defaultInitRouteAuthenticated: 'private/' + workflow + '/stages/LIST_TRANSACTIONS/steps/LIST_TRANSACTIONS',
    middleware: {
        environment: 'accounts',
        module: 'REQUIREMENT'
    },
    staticLinks: {
        login:'login',
        register: 'public/' + workflow + '/stages/CREATE_USER_' + workflow + '/steps/CREATE_USER_' + workflow,
        listTransactions: 'private/' + workflow + '/stages/LIST_TRANSACTIONS/steps/LIST_TRANSACTIONS',
        requirementsUpload: 'private/' + workflow2 + '/stages/DOC_SUPPORT/steps/UPLOAD_REQUIREMENTS',
        detailClientResumen: 'private/' + workflow + '/stages/SUMMARY_ACCOUNT_OPENING/steps/SUMMARY_ACCOUNT_OPENING',
        firstStep: 'private/' + workflow + '/stages/CLIENT_IDENTIFICATION/steps/BUSINESS_IDENTITY'

    },

    theme: {
        images: 'FACEBANK',
        backgroundColor: '#28a745',
        backgroundColorBorderMail: '#28a745',
        fontColor: '#FFFFFF',
        site: {
            name: 'FACEBANK',
            title: 'ARN | CLIENT',
            url: 'http://www.facebank.pr',
            supportMail: 'online@facebank.pt'
        },
        image: {
            active: true,
            urlImageHeader: 'https://akeela.co/resources/images/logo_claro.svg'
        },
        textFooterMail: '',
        sizeImageLogoNavbar: "3",
        sizeImageLogoNavbarMovil: "3",
        sizeImageLogoMenu: "3",

        logoConf: {
            theme: {
                sure: {
                    urlLogo: 'assets/facebank/public/images/logo_claro.svg',
                    urlLogoMin: 'assets/facebank/public/images/logo_claro.svg'
                },

                dark: {
                    urlLogo: 'assets/facebank/public/images/logo_oscuro.png',
                    urlLogoMin: 'assets/facebank/public/images/logo_oscuro.png'
                }
            },
            themeColorDefault: 'sure',
            themeColorNav: 'sure',
            themeColorNavAuth: 'sure',
            themeColorMenu: 'dark',
            themeColorNavModal: 'sure',
            themeColorPdf: 'sure',


            typeDefault: null,
            typeNav: 1,
            typeNavAuth: 1,
            typeMenu: 1,
            typePdf: 1,
            typeNavModal: null,
        },
        showRegister: false,


    },

    apiConfig: {
        useMock: false,
        urlApi: 'http://165.227.199.158:8080/',
        uriWorkflowPrefix: 'workflow_manager',
        initiallyOpenSections: false,
        autoSave:true,
        autoSaveTime:15000,
        currency: {
            prefix: '',
            thousands: ',',
            decimal: '.'
        },
        mask: {
            telephone: 'mask_tlf_2'
        }
    },
    appPropertiesConfig: {
        useMock: false,
        urlApi: 'http://165.227.199.158:8080/',
        uriPropertiesPrefix: 'app_properties'
    },

    appEndPontDefaultConfig: {
        workflowState: 'workflowState',
        stepState: 'stepState',
        transitions: 'transitions',
        sectionsState: 'sectionsState',
        menu: 'menu',
        transactionCode: 'transactionCode',
        stageStatus: 'stageStatus',
        sidebarLeftActive:'HOME',
        showPayroll:false,
        isFieldNotRequired:true
    },
    i18nConfig: {
        tagOpen: '{',
        tagClose: '}',
        constants: {
            clientName: 'FACEBANK',
            clientNameShort: 'FACEBANK',
            bankAccountInstitution: 'Facebank',
            url: 'http://www.facebank.pr'
        }
    },
    wfConfig: {
        useMock: false,
        urlApi: 'http://165.227.199.158:8080/',
        uriWorkflowPrefix: 'workflow_manager',
        initiallyOpenSections: false,
        autoSave:true,
        autoSaveTime:15000,
    },
    security: {
        urlApi: 'http://165.227.199.158:8080/authorization-manager/api/v1',
        clientCode: 'TEST_CLIENT',
        uriLogin: 'security/login',
        uriLogout: 'security/logout',
        urlForgotPassword: 'security/forgot-password',
        uriRefreshToken: 'refresh',
        urlSecurityApi: 'http://165.227.199.158:8080/person'
    },
    paginator: {
        show: true,
        page: 0,
        size: 25,
        maxPages: 10
    },
    auth: {
        activeEncrypt: true,
        hasProfiles: false,
        inactivity: {
            active: true
        }
    },
    configDefauld: {
        showDescriptionReq: false,
        showNoteCategory: false,
        iconProduct: false,
        styleBox: {
            'min-height': 'auto'
        }
    },
    formsValidations: {
        login: {
            username: {
                minLength: 6,
                maxLength: 12
            },
            password: {
                minLength: 8,
                maxLength: 12
            }
        }
    },
    requirements: {
        file: {
            mb_max_filesize: 5
        },
        isFilterRequirementsByResponse: false,
        isFilterRequirementsByCategory: false
    },
    localStorageConfig: {
        activeEncrypt: false
    },
    showOperationDataResumen: true,
    showTransactionsRelatedResumen: false,
    ruleNoDeleteLegalRepresentative: true,
    facialRecognition: {
        debug: false,
        active: false,
        draw: false,
        landmarks: ['expressions', 'landmarks', 'descriptors', 'ageAndGender'],
        maxAttempt: 8,
        hostServices: 'https://face.akeela.co'
    }


};
