<div class=" mb-4">
    <div class="row  mt-3 text-left">

        <div class="col-12 col-md-8 ">

            <h4 class="w-100 title-section mb-3">Continuar</h4>

            <div *ngIf="!dataInformation?.completeBriefcase && indicatorsProgres?.percent && indicatorsProgres?.percent == 100">

                <div class="mb-2">
                    Por favor, asegúrese de que la siguiente información sea correcta. Lo usaremos para generar sus documentos legales.<br> <br>Al finalizar nuestra institución revisará la veracidad de la información.

                </div>

                <div class="mt-4 font-weight-bold">
                    Solicitante
                </div>

                <div class="text-ligth section-box  p-3 mt-3 " *ngIf="isNatural">

                    <div class="text-secondary fz-14">Persona Natural</div>
                    <div class="text-dark text-capitalize">{{dataInformation?.givenName}} {{dataInformation?.familyName}}</div>

                    <div class="text-secondary fz-14 mt-3">Documento de Identidad</div>
                    <div class="text-dark text-capitalize">{{dataInformation?.identity}}</div>

                    <div class="text-secondary fz-14 mt-3">Dirección</div>
                    <div class="text-dark text-capitalize">{{dataInformation?.homeAddress?.addressLocality?.name}}, {{dataInformation?.homeAddress?.city?.name}}, {{dataInformation?.homeAddress?.state?.name}}, {{dataInformation?.homeAddress?.addressCountry?.name}}</div>

                </div>
                <div class="text-ligth section-box  p-3 mt-3 " *ngIf="!isNatural">


                    <div class="text-secondary fz-14">Nombre de la Razón Social</div>
                    <div class="text-dark text-capitalize">{{dataInformation?.legalName}} </div>



                    <div class="text-secondary fz-14 mt-3">Número de Identificación Fiscal</div>
                    <div class="text-dark text-capitalize">{{dataInformation?.taxID}} </div>



                    <div class="text-secondary fz-14 mt-3">Nombre Comercial</div>
                    <div class="text-dark text-capitalize">{{dataInformation?.alternateName}}</div>

                </div>


                <div class="mt-4 ">
                    <button (click)="sendCompleat()" class="btn btn-primary ">Continuar
                        <span class="fas fa-arrow-right ml-1"></span>
                    </button>

                </div>


            </div>
        </div>


        <div class="col-12 col-md-8 " *ngIf="!(!dataInformation?.completeBriefcase && indicatorsProgres?.percent && indicatorsProgres?.percent == 100)">
            <div class="alert alert-secondary p-3 mt-4 mb-4 animate__animated animate__fadeIn " *ngIf="!( indicatorsProgres?.percent == 100)">

                <div class="d-flex fz-14 ">

                    <div class="text-left w-100 ">

                        <div class="text-secondary ">
                            <div> Por favor complete toda la información requerida</div>
                        </div>

                    </div>

                </div>
            </div>


            <div class="text-ligth section-box  p-3 mt-3 " *ngIf="isNatural">

                <div class="text-secondary fz-14">Persona Natural</div>
                <div class="text-dark text-capitalize">{{dataInformation?.givenName}} {{dataInformation?.familyName}}</div>

                <div class="text-secondary fz-14 mt-3">Documento de Identidad</div>
                <div class="text-dark text-capitalize">CI, {{dataInformation?.identity}}</div>

                <div class="text-secondary fz-14 mt-3">Dirección</div>
                <div class="text-dark text-capitalize">{{dataInformation?.homeAddress?.addressLocality?.name}}, {{dataInformation?.homeAddress?.city?.name}}, {{dataInformation?.homeAddress?.state?.name}}, {{dataInformation?.homeAddress?.addressCountry?.name}}</div>

            </div>
            <div class="text-ligth section-box  p-3 mt-3 " *ngIf="!isNatural">


                <div class="text-secondary fz-14 ">Nombre de la Razón Social</div>
                <div class="text-dark text-capitalize">{{dataInformation?.legalName}} </div>



                <div class="text-secondary fz-14 mt-3">Número de Identificación Fiscal</div>
                <div class="text-dark text-capitalize">{{dataInformation?.taxID}} </div>



                <div class="text-secondary fz-14 mt-3">Nombre Comercial</div>
                <div class="text-dark text-capitalize">{{dataInformation?.alternateName}}</div>

            </div>


            <div class="mt-4 ">
                <button (click)="redirectContinue()" class="btn btn-primary ">Continuar 
                    <span class="fas fa-arrow-right ml-1"></span>
                </button>
            </div>


        </div>
    </div>
</div>