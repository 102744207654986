import { ProductsComponent } from "@akeela/products-plans";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//test
// clientCode: 'TEST_CLIENT',

//desarrollo
// clientCode: 'ARN_CLIENT',
// http://3.89.248.120:8080

//prod
// clientCode: 'QUALITY_CLIENT_ARN',
// http://165.227.199.158:8080/


const workflow = 'ACCOUNT_OPENING'; // Esté siempre será el por defecto
const workflow2 = 'DOC_SUPPORT';

export const environment = {
    code:'RV_PROD',

    production: true,
    procedureMail: 'Apertura de Cuenta',
    urlFront: 'https://arn.rendivalores.com/',
    workflow: workflow,
    workflow2: workflow2,
    defaultInitRoute:'login',
    defaultStorefrondRoute: 'http://demobank.rendivalores.co/dev',
    defaultInitRouteAuthenticated: '/dashboard',
    firstStep: 'private/' + workflow + '/stages/CLIENT_IDENTIFICATION/steps/BUSINESS_IDENTITY',

    middleware: {
        environment: 'accounts',
        module: 'REQUIREMENT'
    },
    staticLinks: {
        login:'login',
        register: 'public/' + workflow + '/stages/CREATE_USER_' + workflow + '/steps/CREATE_USER_' + workflow,
        listTransactions: 'private/' + workflow + '/stages/LIST_TRANSACTIONS/steps/LIST_TRANSACTIONS',
        requirementsUpload: 'private/' + workflow2 + '/stages/DOC_SUPPORT/steps/UPLOAD_REQUIREMENTS',
        detailClientResumen: 'private/' + workflow + '/stages/SUMMARY_ACCOUNT_OPENING/steps/SUMMARY_ACCOUNT_OPENING',
        firstStep: 'private/' + workflow + '/stages/CLIENT_IDENTIFICATION/steps/BUSINESS_IDENTITY',

    },
    theme: {
        images: 'RENDIVALORES',
        backgroundColor: '#007bff',
        backgroundColorBorderMail: '#81DAF5',
        fontColor: '#FFFFFF',
        site: {
            name: 'Rendivalores',
            title: 'ARN | CLIENT',
            url: 'https://rendivalores.com/',
            supportMail: 'online@rendivalores.com'
        },
        image: {
            active: true,
            urlImageHeader: 'https://user.rendivalores.com/assets/rendivalores/public/images/rv.png',
        },
        textFooterMail: 'Copyright 2020 rendivalores, Todos los derechos reservados.',
        sizeImageLogoNavbar: "3",
        sizeImageLogoNavbarMovil: "3",
        sizeImageLogoMenu: "3",
        logoConf: {
            theme: {
                sure: {
                    urlLogo: 'assets/rendivalores/public/images/rv.png',
                    urlLogoMin: 'assets/assets/rendivalores/public/images/rv.png'
                },

                dark: {
                    urlLogo: 'assets/assets/rendivalores/public/images/rv.png',
                    urlLogoMin: 'assets/assets/rendivalores/public/images/rv.png'
                }
            },
            themeColorDefault: 'sure',
            themeColorNav: 'sure',
            themeColorNavAuth: 'sure',
            themeColorMenu: 'sure',
            themeColorNavModal: 'sure',
            themeColorPdf: 'sure',


            typeDefault: null,
            typeNav: 1,
            typeNavAuth: 1,
            typeMenu: 1,
            typePdf: 1,
            typeNavModal: null,
        },
        showRegister: true,

    },
    apiConfig: {
        useMock: false,
        urlApi: 'https://services.rendivalores.com:443/',
        uriWorkflowPrefix: 'workflow_manager',
        initiallyOpenSections: false,
        autoSave:false,
        autoSaveTime:15000
        
    },
    appPropertiesConfig: {
        useMock: false,
        urlApi: 'https://services.rendivalores.com:443/',
        uriPropertiesPrefix: 'app_properties'
    },
    appEndPontDefaultConfig: {
        workflowState: 'workflowState',
        stepState: 'stepState',
        transitions: 'transitions',
        sectionsState: 'sectionsState',
        menu: 'menu',
        transactionCode: 'transactionCode',
        stageStatus: 'stageStatus',
        sidebarLeftActive:'HOME',
        showPayroll:true,
        isFieldNotRequired: true,
        hiddeFieldOptional: true


    },
    i18nConfig: {
        tagOpen: '{',
        tagClose: '}',
        constants: {
            clientName: 'Rendivalores',
            clientNameShort: 'Rendivalores',
            bankAccountInstitution: 'Rendivalores',
            url: 'https://rendivalores.com/',
            urlDocumentConditions: '/assets/rendivalores/public/documents/contrato.pdf',
            urlDeclaracion: '/assets/rendivalores/public/documents/Declaracion-Jurada-PN-RV.pdf',
            urlContrato: '/assets/rendivalores/public/documents/Contrato-cuenta-de-corretaje-Rendivalores.pdf',


        }
    },
    wfConfig: {
        useMock: false,
        urlApi: 'https://services.rendivalores.com:443/',
        uriWorkflowPrefix: 'workflow_manager',
        initiallyOpenSections: false,
        autoSave:false,
        autoSaveTime:15000
    },
    security: {
        urlApi: 'https://services.rendivalores.com:443/authorization-manager/api/v1',
        clientCode: 'ARN_RV_CLIENT',
        uriLogin: 'security/login',
        uriLogout: 'security/logout',
        urlForgotPassword: 'security/forgot-password',
        uriRefreshToken: 'refresh',
        urlSecurityApi: 'https://services.rendivalores.com:443/person'
    },
    paginator: {
        show: true,
        page: 0,
        size: 25,
        maxPages: 10
    },
    auth: {
        activeEncrypt: true,
        hasProfiles: false,
        inactivity: {
            active: true
        }
    },
    configDefauld: {
        showDescriptionReq: false,
        showNoteCategory: false,
        iconProduct: true,
        styleBox: {}
    },
    formsValidations: {
        login: {
            username: {
                minLength: 6,
                maxLength: 12
            },
            password: {
                minLength: 8,
                maxLength: 12
            }
        }
    },
    requirements: {
        file: {
            mb_max_filesize: 1
        }
    },
    localStorageConfig: {
        activeEncrypt: false
    },
    responseOptionalFieldsIndividualResponse: {
        observationInside: true,
        reasonIncompleted: [
            {
                identifier: '',
                name: '@i18n-requirements-ilegible'
            },
            {
                identifier: '',
                name: '@i18n-requirements-desactualizado'
            },
            {
                identifier: '',
                name: '@i18n-requirements-information-additional-required'
            },
        ]
    },
    showOperationDataResumen: true,
    showTransactionsRelatedResumen: true,
    showTips: true,
    
    facialRecognition: {
        debug: false,
        active: true,
        draw: false,
        liveVideoActive: false,
        liveVideoValidationActive: false,
        landmarks: ['expressions', 'landmarks', 'descriptors', 'ageAndGender'],
        maxAttempt: 8,
        hostServices: 'https://face.akeela.co'
    },
    visor: 'alternative'
};
// 'expressions', 'landmarks', 'ageAndGender'
// ['expressions', 'landmarks', 'descriptors', 'ageAndGenders']
