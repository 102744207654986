import { AuthService, ErrorService } from '@akeela/auth';

import { LocalService } from '@akeela/local-storage';
import { HttpService } from '@akeela/properties';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { WorkflowService } from 'src/app/services/workflow.service';
import {  Router } from '@angular/router';
import { timer } from 'rxjs';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import swal from 'sweetalert2';


@Component({
  selector: 'app-akeela-dashboard',
  templateUrl: './akeela-dashboard.component.html',
  styleUrls: ['./akeela-dashboard.component.css']
})
export class AkeelaDashboardComponent implements OnInit, OnDestroy {
  usuario;
  identityCompleat;
  init_meet;
  user_meet;

  _second = 1000;
  _minute = this._second * 60;
  _hour = this._minute * 60;
  _day = this._hour * 24;
  end: any;
  now: any;
  day: any;
  hours: any;
  minutes: any;
  seconds: any;
  source = timer(0, 1000);
  clock: any;
  rowInterviews;

  dataInformation;
  env = environment;
  animate_init = true;
  animate_init_bottom = true;
  buttomModal;
  clientDetail;
  back = false;
  menuSelect = false;
  shared =  false;
  newInterview = false
  dataAndSelfi;
  filePreview;
  client;
  dataIdentity;
  compleatVerifi;
  loading = true;
  loadingTransaction = true;
  agencyEjecutive;
  verifiedIdentification;
  copy;
  interviews = [];
  interviewsObj= {};
  rows = [];
  transactions;
  transaction;
  interviewTypeIcon = [];
  interviewType = [];
  formsDataResponse = {};
  interviewsTime={};
  interviewsTimeProgram;
  interviewsTimeProgramTemp;
  localDirection;
  localDirectionFull;
  showVerification;
  indicatorsProgres;
  indicatorsProgresTransactionData;
listWf = {};
newTransaction ;
validMeet;

public safe_url: SafeResourceUrl;
isNatural;
contactar;

  constructor(
    private _wfService: WorkflowService,
    private router: Router,
    private _httpService: HttpService,
 
    private datePipe: DatePipe,
    public _auth: AuthService,
    public localStorage: LocalService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private akWorkflowService: AkeelaWorkflowService,
    private sanitizer: DomSanitizer,



  ) { }


  ngOnInit(): void {
  this.isNatural = this.localStorage.getItem('clientaudience') == 'NATURAL';


  if (environment.theme.images === 'RENDIVALORES') {
    
    this.safe_url = this.safe_url_('https://www.youtube.com/embed/juaW691j9lI?autoplay=0&controls=1&rel=0&showinfo=0&modestbranding=1');


  }else{

    this.safe_url = this.safe_url_('https://www.youtube.com/embed/wsKoBTDWaYQ?autoplay=0&controls=1&rel=0&showinfo=0&modestbranding=1');
    
  }
   
   
    this.akWorkflowService.setTransactionNumber(null);
    this.akWorkflowService.transactionData$.next(null);

     this.getDataInformations();
     this.getAgencyEjecutive();


    if(!this.localStorage.getItem('showVerification') ||  this.localStorage.getItem('showVerification') == 'true' ){
      this.showVerification = true;

    }else{
      this.showVerification = false;

    }


    this._akeelaSidebarMenu.setshowSidebarLeft(false);

    this.getInterviews();

this.getInterviewType();

    this.getTransactions();

    this.usuario = this._auth.getSession();
    this._wfService.setShowBackDashboard(false);


    this._wfService.getIdentityCompleat$.subscribe((data: any) => {
      
      this.identityCompleat = data;
             
    });



        /************************* */

        const options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        };
        
        if (navigator.geolocation){
          navigator.geolocation.getCurrentPosition((pos) => {
           // console.log(`<p>Latitud: ${pos.coords.latitude}</p>`) ; 
           //  console.log(`<p>Longitud: ${pos.coords.longitude}</p>`);   
            var Http = new XMLHttpRequest();
            var latitud = pos.coords.latitude;
            var logitud = pos.coords.longitude;
            var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitud + ',' + logitud
                + '&key=AIzaSyD4vQN9WVbH1RVFnoWDe1DNDxkHoV2u4_k';
            Http.open('POST', url);
            Http.send();
            Http.onreadystatechange = (e) => {
             let response = Http.responseText;

             if(response && response != ''){
              this.localDirection = JSON.parse(response)?.results[0];
              this.localDirectionFull = {};
             this.localDirection?.address_components?.filter((resp:any) => {
          let data = {};
          if(resp?.types && resp?.types[0]){
            this.localDirectionFull[resp?.types[0]] = resp?.long_name;

          }

                return true;

              },this);

             }else{
              this.localDirectionFull = null;

             }

      

            }
            
          },(error) => {
            switch(error.code) {
              case error.PERMISSION_DENIED:
                console.log("User denied the request for Geolocation.")
                break;
              case error.POSITION_UNAVAILABLE:
                console.log("Location information is unavailable.")
                break;
              case error.TIMEOUT:
                console.log("The request to get user location timed out.")
                break;
            }
          },options);
        } else {
         // console.log("Your browser doesn't support geolocation.")
        }
        
        // Usando watchPosition()
        let watchID = navigator.geolocation.watchPosition((pos)=> {
    
       //   console.log(`<p>Latitud: ${pos.coords.latitude}</p>`) ; 
        //  console.log(`<p>Longitud: ${pos.coords.longitude}</p>`);   
       
        });
        
        navigator.geolocation.clearWatch(watchID);
        
        ///////*********///////// */

        this._wfService.listWF$.subscribe((listWf: any) => {

          if(listWf){

            this.listWf = listWf;

          }
                 
        });


  }



  public safe_url_(url): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

getListWf(wf){
    return   this.listWf[wf];


}

   ngOnDestroy(): void {
    this.localStorage.removeItem('redirectTransation');

    this._wfService.setShowBackDashboard(true);
   }



  redirectTransactions(valor = false){

    this._wfService.setshowIdentityVerification(false);
    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience'),
    };

    const prefixed = 'private';
    const url = 'ACCOUNT_OPENING/stages/LIST_ACCOUNT_OPENING/steps/LIST_ACCOUNT_OPENING';

    if(valor){
      this.localStorage.setItem('newProduct',true)

    }

    this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });



  }



setIdentity(){
//    this.router.navigate(['private/ACCOUNT_OPENING/stages/INTER_IDENTITY_VERIF/steps/INTER_IDENTITY_VERIF_PHOTOS'], {

    this.router.navigate(['private/ACCOUNT_OPENING/stages/INTER_IDENTITY_VERIF/steps/INTER_IDENTITY_VERIF_PHOTOS'], {
      queryParams: {
        role: 'CLIENT',
        profile: 'CLIENT',
        audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL'
      }
    });


}

redirectInbox(code, sidebar) {

  const prefixed = 'private';
  const url = 'ACCOUNT_OPENING/stages/LIST_ACCOUNT_OPENING/steps/LIST_ACCOUNT_OPENING';

  const queryParams = {
    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    code: code
  };



  this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });



}

getMeet(){
  this.user_meet = this._auth.getUsername();
  this.init_meet = true;
}

setMeetData(e) {
    this.init_meet = false;
    this.user_meet = null;
    
}

setNewTransaction(e) {

  this.newTransaction = false;  
}




redirectProducts(){

  window.location.href = `${environment.defaultStorefrondRoute}`;  
}


getTransactions() {
  const params = {
    name: 'account$myformalities_paginated$get',
    params: {
      request: {
        page: 0,
        size: 60
      },
      path: {
        username: this._auth.getUsername()
      },
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    this.transactions = response.content;
    this.rows = [];

    let  trans;
    let dateInt  = 0;

    for (const transaction of this.transactions) {

      let row = transaction;

      if(row.accountStatus[0].dateOfThing.startDate > dateInt && (row.accountStatus[0].additionalType == 'PI_IN_PROCESS' ||  row.accountStatus[0].additionalType == 'AC_INCOMPLETE')){

          trans = transaction;
      }

      this.getInterview(row?.identifier, row?.transactionNumber);

    
    }

    this.transaction = trans;
  
    this.getIndicatorsProgres(trans?.plan?.url,trans);

    this.loadingTransaction = false;

  }, error => {
    this.loadingTransaction = false;

  });
}


getInterview(transaction,transactionNumber) {
  const params = {
    name: 'interview$interviews_transaction$get',
    params: {
      request: {
        idTransaction: transaction
      },
      path: { 

      },
      body: {}
    }
  };

     this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    if(response){
      response['transaction'] = transactionNumber; 
      response['idTransaction'] = transaction; 


      this.interviews = [...this.interviews, response];

      this.interviewsObj[transaction] = response;
  
     this.interviewsTimeProgramTemp = null;
      this.setTimeInterview(response);

    }

  }, error => {
  });

}


setTimeInterview(response){
  this.interviewsTime[response?.idTransaction]= {
    now:'',
    end:'',
    day:'',
    hours:'',
    minutes:'',
    seconds:'',
    valid:''
  }

  this.clock = this.source.subscribe(t => {

  this.interviewsTime[response?.idTransaction]['now'] = new Date();
  //  this.end = new Date('01/01/' + (this.now.getFullYear() + 1) +' 00:00');
  let temp = this.toDateStringTime(response?.interviewDate);
 // let temp2 = '01/01/' + (this.interviewsTime[response?.idTransaction]['now'].getFullYear() + 1) +' 00:00';

  this.interviewsTime[response?.idTransaction]['end'] = new Date(temp);
  this.interviewsTime[response?.idTransaction]['valid'] = this.interviewsTime[response?.idTransaction]['end'] > this.interviewsTime[response?.idTransaction]['now'];
 
 
  // this.interviewsTime[response?.idTransaction]['end'] = this.toDateString(response?.interviewDate);
  if(this.interviewsTime[response?.idTransaction]['valid']){
    this.validMeet = true;

     this.showDate(response?.idTransaction);

     if(this.interviewsTimeProgramTemp == null || this.interviewsTime[response?.idTransaction]['end'] < this.interviewsTimeProgramTemp){
      this.interviewsTimeProgramTemp = this.interviewsTime[response?.idTransaction]['end'];
      this.interviewsTimeProgram = response?.idTransaction;
      this.rowInterviews = this.interviewsObj[this.interviewsTimeProgram];
     }
  }

  });
}

getInterviewType() { 
  const params = {
    name: 'workflow_manager$attributes_options$get',
    params: {
      request: {},
      path: { 
        code: 'interviewType'
      },
      body: {}
    }
  };
  

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    let nameItem = '';

    if(response.length){


      this.interviewType = [];
      for (let value of response) {
        nameItem = value.item.alternateName;
      if(nameItem){

        this.interviewType[nameItem] =   {value:nameItem, label: value.name} ;

      }
                


      }}
  
    });

}


convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
  
}

private toDateStringTime(fecha): string {
  let temp = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);
  let date:Date = this.parseDateString(temp);

  return (  ("0" + (date.getMonth() + 1)).slice(-2)  + '/' + ("0" + (date.getDate())).slice(-2)  + '/' +date.getFullYear().toString())
   + ' ' + date.toTimeString().slice(0,5);
}



 toDateString(fecha): string {
  let temp = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);
  let date:Date = this.parseDateString(temp);

  return (("0" + (date.getDate())).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' +date.getFullYear().toString())
   + ' ' + date.toTimeString().slice(0,5);
}


showDate(idTransaction){
  let distance = this.interviewsTime[idTransaction]['end'] - this.interviewsTime[idTransaction]['now'];
  this.interviewsTime[idTransaction]['day'] = Math.floor(distance / this._day);
  this.interviewsTime[idTransaction]['hours']  = Math.floor((distance % this._day) / this._hour);
  this.interviewsTime[idTransaction]['minutes']  = Math.floor((distance % this._hour) / this._minute);
  this.interviewsTime[idTransaction]['seconds']  = Math.floor((distance % this._minute) / this._second);
}



 parseDateString(date:string): Date {
  date = date.replace('T','-');
  let parts:any = date.split('-');
  let timeParts = parts[3].split(':');
  
 // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1]-1, parts[2], timeParts[0], timeParts[1]); // Note: months are 0-based

}

redirecClient2(){
  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
  };

  let url = ['/user-account'];

  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
    
  });
}


redirecClient(){
this.akWorkflowService.setCurrentWorkflowCodeActive('CLIENT_DATA');

  

  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    section:'PERSONAL_INFORMATION'

  };

  let url = ['private/' + 'CLIENT_DATA'+ '/stages/CLIENT_IDENTIFICATION/steps/PERSONAL_INFORMATION'];

  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
  });

}

redirecClientData(){

  

  this.akWorkflowService.setCurrentWorkflowCodeActive('CLIENT_DATA');

  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
  };

  let url = ['/client-data'];

  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
    
  });
}


redirectContinue(urlWf = 'CLIENT_DATA' ) {
  
  this.akWorkflowService.setCurrentWorkflowCodeActive('CLIENT_DATA');


  if(!this.dataInformation?.completeBriefcase){

 let url = [urlWf];
 const prefixed = 'private';

  const queryParams = {
    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience')
  };


  this.localStorage.setItem('sectionRedirect',true);

  this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });

}
}


redirectDirection(){

  this.akWorkflowService.setCurrentWorkflowCodeActive('CLIENT_DATA');


  this.redirectSection('CLIENT_DATA', 'CLIENT_IDENTIFICATION','PERSONAL_INFORMATION','HOME_ADDRESS' );
}

redirectSection(urlWf, stage,step,section ) {



  const prefixed = 'private';
 let url = [urlWf + '/stages/' + stage + '/steps/' + step];

  const queryParams = {
    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    section: section,

  };


  this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });
  
}

setShowVerification(){
  this.showVerification = !this.showVerification;
  this.localStorage.setItem('showVerification',this.showVerification);
}


redirectTransactions2(transaction){

  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
  };

  let url = ['/transaction-dashboard'];
  this.akWorkflowService.setTransactionNumber(transaction?.transactionNumber);


  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
    
  });
}



getDataInformations() {
  const params = {
    name:  this._auth.getAdminOrganizationIdentifier()? 'briefcase$business_person$get' : 'briefcase$person$get',
    params: {
      request: {
        'id': this.localStorage.getItem('clientIdentifier')
      },
      path: {},
      body: {}
    }
  };
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
   this.dataInformation = response;
      this.getIndicatorsProgres('CLIENT_DATA');
   

  },error=>{

    
  });
  


}


getAgencyEjecutive() {
  const params = {
    name: 'person$user_executive_assigned$get',
    params: {
      request: {
        'username': this._auth.getUsername()
      },
      path: {},
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

   this.agencyEjecutive = response;

  });


}


sendCompleat() {
  

  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    section:'SEND_CUSTOMER_DATA'

  };

  this.akWorkflowService.setCurrentWorkflowCodeActive('CLIENT_DATA');
  let url = ['private/' + 'CLIENT_DATA'+ '/stages/CLIENT_IDENTIFICATION/steps/SEND_CUSTOMER_DATA'];
  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
  });

}


backCompleat() {
  let data :any;
 
  const params = {

    name: 'briefcase$reset_complete-data$post',
    params: {
      request: {
        'username': this._auth.getUsername()
      },
      path: {},
      body: {}
    }
  };


  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      if (response) {
        this.getDataInformations();

      }
  }, error => {


  });

}



getIndicatorsProgres(workflowCode,transactionData:any = false) {

  let data :any;

  if(workflowCode && transactionData){

    let transactions = [];
        transactions = [...transactions, {transactionNumber: transactionData?.transactionNumber}]
    data = {
      "workflow":workflowCode,
      bankAccountTransactions :transactions
    } ;

  }   if(workflowCode && !transactionData){



    data = {
      "workflow":workflowCode,
      "personId": this.localStorage.getItem('clientIdentifier')
  } ;


  }
 
    
 
  const params = {

    name: 'resume$workflow_progress$post',
    params: {
      request: {
        role: this.localStorage.getItem('role'),
        audience: this.localStorage.getItem('audience'),

      },
      path: {},
      body: data

    }
  };

  let indicatorsProgres = {};
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    if (response) {
      let progress;
      let preData =response[0]?.modules;

      if(transactionData){
      let  progress2 = preData?.filter((resp) => {
          return resp?.alternateName == workflowCode;
        });
       progress  = progress2[0].progress;

      }else{
       progress = response[0]?.modules[0]?.progress;

      }
    

       let  maxValue = progress?.maxValue;
       let  minValue = progress?.minValue;

       indicatorsProgres['maxValue'] = maxValue;
       indicatorsProgres['minValue'] = minValue;
       indicatorsProgres['percent'] = minValue > 0 ?   this.round(minValue*100/maxValue):0;
        ;


        if(transactionData){

          this.indicatorsProgresTransactionData = indicatorsProgres;

        }else{

          this.indicatorsProgres = indicatorsProgres;


        }


       
      }


  }, error => {


  });

}


round(value) {
  return Math.round(value);
}

gotoGoogle() : void {

  if (environment.theme.images === 'CARONI') {
    window.open("https://www.google.com/maps/place/Banco+Caron%C3%AD/@10.5028004,-66.907164,15z/data=!4m5!3m4!1s0x0:0x413ec06f5e965882!8m2!3d10.5027883!4d-66.9071264", "_blank");

} else if (environment.theme.images === 'RENDIVALORES') {
  window.open("https://www.google.com/maps/place/Rendivalores+Casa+de+Bolsa+C.A./@10.497656,-66.851148,15z/data=!4m5!3m4!1s0x0:0xda6b9d20a484cb94!8m2!3d10.4976151!4d-66.8511371", "_blank");

} 

}


extranet() : void {

    window.open("https://extranet.mercosur.com.ve/AKBrokerage/sign-in", "_blank");


}



gotoGoogleAll() : void {
  if (environment.theme.images === 'CARONI') {
    window.open("https://www.google.com/maps/place/Banco+Caron%C3%AD/@10.5028004,-66.907164,15z/data=!4m5!3m4!1s0x0:0x413ec06f5e965882!8m2!3d10.5027883!4d-66.9071264", "_blank");

} else if (environment.theme.images === 'RENDIVALORES') {
  window.open("https://www.google.com/maps/place/Rendivalores+Casa+de+Bolsa+C.A./@10.497656,-66.851148,15z/data=!4m5!3m4!1s0x0:0xda6b9d20a484cb94!8m2!3d10.4976151!4d-66.8511371", "_blank");

} 
}

gotoWhatsapp(num) : void {
  window.open(" https://wa.me/58"+num.replace('-', '').replace('.', ''), "_blank");
}


getInterviews() {
  const params = {
    name: 'interviews$interviewClients$get',
    params: {
      request: {
      },
      path: {
        'username':  this._auth.getUsername()

      },
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    this.interviews = response;
  });

}


setNoteNew = async () =>{

  const env: any = environment;

   const data =  {
    "environment_code": env?.code || env?.theme?.images,
     "type": "CONTACT",
     "show_note": "TRUE",
     "note_extra_data_json": "",
     "client":this.localStorage.getItem('clientUsername'),
     "full_name":  this.localStorage.getItem('clientName') ,
     "ejecutive":this._auth.getUsername(),
     "ejecutive_update": "",
     "title": '',
     "subtitle": 'Nueva solicitud creada',
     "status_view": "CONTACT",
     "extra_data": "",
     "note": `El cliente requiere ser contactado`,
   };
 debugger

 try {
  await this.saveNote(data);

  this.contactar = true;
  swal.fire({
    html: 'Solicitud de contacto exitosa'
  });
 } catch (error) {
   console.log(error);
   
 }
   

 
 };


 saveNote = async (body:any, url = 'https://utilities.akeela.co/note') => {

  const params = {
    pathResponse: {
      url,
      httpMethod: 'POST'
    },
    params: {
      request: {},
      body

    }
  }

  return this._httpService.executeRequestExternalUrl(params).toPromise();
  
}


}
