import { Component, OnInit, OnDestroy, DoCheck, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AkeelaSectionService, AkeelaStepService, AkeelaWorkflowService, AkeelaStageService } from '@akeela/workflow';
import { ActivatedRoute, Router } from '@angular/router';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { AuthService } from '@akeela/auth';
import { PathService, HttpService } from '@akeela/properties';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { Subscription } from 'rxjs';
import { TransitionService } from '../../../../services/transition.service';
import { MiddlewareService } from '../../../../services/middleware.service';
import { WorkflowService } from '../../../../services/workflow.service';
import { LocalService } from '@akeela/local-storage';
import { TemplateService } from '../../../../services/template.service';
import { min } from 'moment-mini-ts';
import { PropertiesService } from 'src/app/services';
import { clear } from 'console';


@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {

  breadcrumb;
  borderBox: Boolean = false;
  showSubHeaderStep;
  showWorkflow;
  showSubHeaderStepSubscription: Subscription;
  transitionSubscription: Subscription;
  breadcrumbSubscription: Subscription;
  workflowSubscription: Subscription;
  stageSubscription: Subscription;
  queryParamsSubscription: Subscription;
  stepServiceSubscriptor: Subscription;
  breadcrumbServiceSubscriptor: Subscription;
  
  env = environment;

  isAuth = false;
  link = null;

  title = '';
  subtitle = '';
  histotyBak;
  showWindow: Boolean = true;
  queryParams;
  transitionShowWorkflowSubscription: Subscription;
  transitionShowHeaderStageSubscription: Subscription;
  transitionShowHeaderStepSubscription: Subscription;
  stepServiceBreadcrumbStepSubscription: Subscription;
  workflowStatusSubscription: Subscription;

  showSidebarLeft: boolean;
  showChangePass;
  sizeMenuLeft;
  dataWorkflowCode_subscriber: Subscription;
  wfSubscription: Subscription;
  currentWorkflowDefault;
  currentWorkflowChange = [];
  clientinfo = {};
  requirements = {};
  transactionData = null;

  resumen_requirements = null;
  templates: any = {};
  paramsSubscription: Subscription;
 containerFluid;
 workflowStatusMenu;
 nodeActive;
 workflowStatus = {};
 stepsStates =[];
sectionsStates =[];
elemento;
pos;
animate_init_bottom = true;
buttomModal;

transitionsWfs = null;
dropDownTransitionsWfs = {};

sectionName;
indicatorsProgres;
indicatorsLoading;
optionsDataWF :any = {};
 //juan temporal
 workflowCodeActive ;
elemento2;
wfMenuTransitionsActive;
sectionInactive;
sectionWfActual;
continueSection;
  constructor(
    private akeelaSectionService: AkeelaSectionService,
    private route: ActivatedRoute,
    private router: Router,
    private stepService: AkeelaStepService,
    private _auth: AuthService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _pathService: PathService,
    private workflowService: AkeelaWorkflowService,
    private akStepService: AkeelaStepService,
    private akStageService: AkeelaStageService,
    private _transition: TransitionService,
    private _breadcrumbService: AkeelaBreadcrumbService,
    private _middleware: MiddlewareService,
    private _wfService: WorkflowService,
    private _httpService: HttpService,
    private localStorage: LocalService,
    private templateService: TemplateService

  ) {
  }


  @ViewChild('menufixed') menufixed: ElementRef;
  @ViewChild('menuminfixed') menuminfixed: ElementRef;

 
  public ngDoCheck() {

    this.pos = window.pageYOffset;

    this.elemento = this.menufixed?.nativeElement;
    this.elemento2 = this.menuminfixed?.nativeElement;

    if ( this.pos > 84) {

       this.elemento?.classList?.add('ak-menu-saltar-fixed');

       if(this.indicatorsProgres){
        this.elemento2?.classList?.add('ak-menu-min-saltar-fixed-progres');

       }else{
        this.elemento2?.classList?.add('ak-menu-min-saltar-fixed');
       }

  
    }else{
          this.elemento?.classList?.remove('ak-menu-saltar-fixed');


       if(this.indicatorsProgres){
        this.elemento2?.classList?.add('ak-menu-min-saltar-fixed-progres');

       }else{

        this.elemento2?.classList?.add('ak-menu-min-saltar-fixed');

       }
    }
  

    if (this.showSidebarLeft && this._auth.isAuthenticated()) {

      this.sizeMenuLeft = true;

    } else {

      this.sizeMenuLeft = false;
    }
  }

  setShowChangePassword(param) {
    this._auth.setShowChangePassword(param);

  }

  
  ngOnInit() {

    // control de cache por versión
  //  const config = require('../../../../../../package.json'); 
  //  console.log("************************************************");
  //  console.log("Akeela System Version: "+config.version);      
  //  console.log("************************************************");
  //  if(isNullOrUndefined(this.localStorage.getItem('version'))) {
  //   this.localStorage.setItem('version', config.version);
  //  }
   
    this.workflowStatusMenu = null;


    this.queryParamsSubscription = this.route.queryParams.subscribe(queryParams => {

      this.sectionName = null;
      

      if(queryParams?.section){
        this.sectionName = queryParams?.section;
        this.sectionWfActual = true;
        this.nodeActive = queryParams?.section;
        this.buttomModal = false;


        if(this.transitionsWfs){

        let step =    this.transitionsWfs[this.sectionName]?.active?.step;

        if(step){
          this.dropDownTransitionsWfs[step] = true;
        }
        }

      }else{
        this.sectionWfActual = false;

      }

      this.queryParams = queryParams;


    });


    this.workflowService.currentWorkflowActive$.subscribe(resp => {

      
      
      this.sectionWfActual = true;

      this.indicatorsProgres = null;
      if(resp && this.workflowCodeActive  != resp ){

        this.workflowCodeActive = resp;
        this.getDataWorkflows();


      }

    });
  
  

    this.workflowService.wfTransitionsActive$.subscribe(resp => {
      this.wfMenuTransitionsActive = resp;

  });


    if (this.wfSubscription) {
      this.wfSubscription.unsubscribe();
    }

    this.wfSubscription = this.workflowService.currentWorkflow$.subscribe(workflowCode => {
      this.getDataWorkflowCode(workflowCode);
    });

    this.workflowService.worflowEvents$.subscribe((event: any) => {
      if (!isNullOrUndefined(event)) {

        if (event && event.action === 'showLoadingSuppliers') {

        } else if (event && event.action === 'openModal') {
          
          if (event && event.value === 'SHAREHOLDERS_MODAL') {
            this.workflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_LEGAL_FIELDS', 'hidden': true });
            this.workflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_NATURAL_FIELDS', 'hidden': false });
            this.workflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_PEP', 'hidden': false });
        }

        }else if (event && ( event?.action === 'briefcase$ownbusiness$post'  ||  event.action === 'briefcase$labor_dependency$post' ))  {
          this.continueSection = true;
          this.getOptionsMenu(this.workflowCodeActive);

          }
        
      }
      


    });

    

    this._akeelaSidebarMenu.showSidebarLeft.subscribe((data: boolean) => {
      this.showSidebarLeft = data;
    });


    this._auth.isAuth$.subscribe((isAuth: boolean) => {
      this.isAuth = isAuth;
    });


    this.showWorkflow = false;

    this.showSubHeaderStepSubscription = this.akStepService.showSubHeaderStep$.subscribe(data => {
      this.showSubHeaderStep = data;
      this._transition.showWorkflow$.next(true);
    });


    this.transitionShowWorkflowSubscription = this._transition.showWorkflow$.subscribe((showWorkflow) => {
      this.showWorkflow = showWorkflow;
    });

    this.workflowSubscription = this._wfService.showContainer$.subscribe((response) => {
      this.showWindow = response;
    });

   this.transitionSubscription =  this.workflowService.transactionData$.subscribe((response: any) => {
      this.transactionData = response;
      if (!isNullOrUndefined(response)) {  
        this.getIndicatorsProgres(true);
        this.resetStatus();

        this.transactionData = response;
       this.workflowService.setCurrentWorkflowCodeActive(this.transactionData?.plan?.url);
        let footerid =  document?.getElementById("footerid");
  
      }else{
        
        let footerid =  document?.getElementById("footerid");

        this.transactionData = null;

      }
    });

    this.workflowService.currentWorkflow$.subscribe((resp) => {
      if (!isNullOrUndefined(resp)) {
        if (resp === 'DOC_SUPPORT') {
          this.resumen_requirements = false;
        } else {
          this.resumen_requirements = false;
        }
      }
    });

    this.stageSubscription = this.akStageService.showHeaderStage$.subscribe(data => {

      const stage = this.akStageService.currentStage$.getValue();

      if ((stage === 'DOC_SUPPORT' || stage === 'SUMMARY_ACCOUNT_OPENING' || stage === 'PRODUCT_INFORMATION' || stage === 'CLIENT_IDENTIFICATION' || stage === 'ACCOUNT_CONTRACT')) {

        this.link = true;

      } else {

        this.link = false;

      }


      this.clientinfo = {};
      this.clientinfo['name'] = 'clientinfo';
      this.clientinfo['description'] = '@i18n-workflow-name-accountopening';

      this.currentWorkflowDefault = this.workflowService.currentWorkflow$.getValue();
      this.setCurrentWorkflowChange();

      this._middleware.isModuleRequirementsActive().then((response) => {
        if (response === true) {
          if (this.workflowService.currentWorkflow$.getValue() === 'DOC_SUPPORT') {
          //  this.currentWorkflowDefault = 'REQUIREMENT';
          }
          this.requirements['name'] = 'requirements';
          this.requirements['description'] = '@i18n-workflow-name-docsupport';
        }
        this.setCurrentWorkflowChange();
      });

      if (data === true) {

        this.borderBox = true;

      } else {

        this.borderBox = false;

      }

      this.akStepService.showHeaderStep$.subscribe(data2 => {


        if (data2 || data) {

          this.borderBox = true;

        } else {

          this.borderBox = false;

        }

      });

    });

    this.processBreacrum();
    if (this.localStorage.getItem('showSidebarLeft') === null) {
      if (screen.width > 768) {
        this._akeelaSidebarMenu.setshowSidebarLeft(true);
      }
    } else {

      if (this.localStorage.getItem('showSidebarLeft') === 'true') {
        this._akeelaSidebarMenu.setshowSidebarLeft(true);
      } else {
        this._akeelaSidebarMenu.setshowSidebarLeft(false);
      }
    }


   this.workflowService.resetEstatus$.subscribe((response) => {
      if (!isNullOrUndefined(response) && this.workflowCodeActive) {
        

     //  this.workflowStatus = response;
     if(this.workflowCodeActive && this.workflowCodeActive != 'ACCOUNT_OPENING' && this.workflowCodeActive != 'INTER_IDENTITY_VERIF' ){
      this.resetStatus();

       this.getIndicatorsProgres(this.optionsDataWF?.productCode);
       
     }
        
      }
    });





  }



  initWorkflowActive(){

    if(this.workflowCodeActive &&  this.optionsDataWF?.endpointStatus){

      if( !this.optionsDataWF?.productCode){

        this.workflowService.transactionData$.next(null);
        this.workflowService.setTransactionNumber(null);
        this.getIndicatorsProgres(this.optionsDataWF?.productCode);
        this.histotyBak = 'client-data';

      }else{
        
        this.histotyBak = 'transaction-dashboard';

      }


      this.workflowService.setWfTransitionsActive(true);
      this.getOptionsMenu(this.workflowCodeActive);
      this.containerFluid = true;


    }else{
      this.workflowService.setWfTransitionsActive(false);
      this.workflowStatusMenu = null;
      this.transitionsWfs = null;
      this.containerFluid = false;
      this.indicatorsProgres = null;
      this.sectionWfActual = true;

    }
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.templates = this.templateService.getTemplates();
    }, 1);
  }

  setBreacrumb(breadcrumbKey) {
    this._breadcrumbService.setbreadcrumbKey(breadcrumbKey);
  }

  processBreacrum() {
    const breadcrumb = null;
    this.stepServiceBreadcrumbStepSubscription = this.stepService.breadcrumbStep$.subscribe(breadcrumbKey => {

      if (breadcrumbKey) {
        this.setBreacrumb(breadcrumbKey);
      } else {
        this._breadcrumbService.setbreadcrumbOptions(null);
      }
    });

  }



  getDataWorkflowCode(workflow) {
    if (workflow) {
      this.subtitle = '';

      const param = {
        name: 'workflow$workflow$get',
        params: {
          request: { content: 'none' },
          path: { workflow: workflow },
          body: {}
        }
      };

      this.dataWorkflowCode_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {

        this.subtitle = response.name;

      });
    }
  }


  deleteSubscription() {
    if (this.showSubHeaderStepSubscription) {
      this.showSubHeaderStepSubscription.unsubscribe();
    }

    if (this.breadcrumbSubscription) {
      this.breadcrumbSubscription.unsubscribe();
    }

    if (this.transitionShowWorkflowSubscription) {
      this.transitionShowWorkflowSubscription.unsubscribe();
    }

    if (this.transitionShowHeaderStageSubscription) {
      this.transitionShowHeaderStageSubscription.unsubscribe();
    }

    if (this.transitionShowHeaderStepSubscription) {
      this.transitionShowHeaderStepSubscription.unsubscribe();
    }

    if (this.stepServiceBreadcrumbStepSubscription) {
      this.stepServiceBreadcrumbStepSubscription.unsubscribe();
    }

    if (this.dataWorkflowCode_subscriber) {
      this.dataWorkflowCode_subscriber.unsubscribe();
    }
    if (this.wfSubscription) {
      this.wfSubscription.unsubscribe();
    }

  }

  ngOnDestroy(): void {

    this.workflowStatusMenu = null;
    this.deleteSubscription();
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }

    if(this.workflowStatusSubscription){
      this.workflowStatusSubscription.unsubscribe();
    }

    if(this.transitionSubscription){
      this.transitionSubscription.unsubscribe();
    }

    this.akeelaSectionService.background$.next(false);
  }

  get_response_breadcrub(response) {
    console.log(response);
    if (!isNullOrUndefined(response) && response !== '') {
      this.router.navigateByUrl(this.router.createUrlTree
        (['private/' + response],
        {
          queryParams: this.queryParams
        })
      );
    }
    // if (response === 'REQUIREMENT') {
    //   // redirecciona a requirements wf

    //   this.router.navigateByUrl(this.router.createUrlTree
    //     ([environment.staticLinks.requirementsUpload],
    //     {
    //       queryParams: this.queryParams
    //     }
    //     )
    //   );

    // } else {

    //   this.router.navigateByUrl(this.router.createUrlTree
    //     ([environment.staticLinks.detailClientResumen],
    //     {
    //       queryParams: {}
    //     }
    //     )
    //   );

    // }
  }


  setCurrentWorkflowChange() {
    this.currentWorkflowChange = [];
    if (!(Object.keys(this.clientinfo).length === 0)) {
      this.currentWorkflowChange.push(this.clientinfo);
    }
    if (!(Object.keys(this.requirements).length === 0)) {
      this.currentWorkflowChange.push(this.requirements);
    }



  }

  eventBreadcrumb(response) {
    if (response.action === 'navigate') {
      if (!isNullOrUndefined(this.localStorage.getItem('transactionModeEdit'))) {
        //this.breakfreeTransactionEdit(); // Mario: este caso era de liberación individual, pero debido a que ahora se va a liberar por usuario, se comenta para ver el comportamiento
        this.breakfreeTransactionsByUser();
      } else {
        this.breakfreeTransactionsByUser();
      }
    }
  }


  breakfreeTransactionsByUser() {
    //alert('voy a ejecuctar 3');

    const configuration = {
      name: 'account$finish_editing_role_by_user$put',
      params: {
        path: {},
        request: {
          role: 'CLIENT',
          holderId: this.localStorage.getItem('audience') === 'LEGAL' ? this._auth.getAdminOrganizationIdentifier() : this.localStorage.getItem('clientIdentifier')

        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
    });
  }

  breakfreeTransactionEdit() {
    //alert('voy a ejecuctar 333333');

    const configuration = {
      name: 'account_opening$finish_editing_role$put',
      params: {
        path: {
          transactionNumber: this.localStorage.getItem('transactionModeEdit')
        },
        request: {
          role: 'CLIENT',
          holderId: this.localStorage.getItem('audience') === 'LEGAL' ? this._auth.getAdminOrganizationIdentifier() : this.localStorage.getItem('clientIdentifier')

        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.workflowService.setTransactionNumber(null);
    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.workflowService.transactionData$.next(null);

    });
  }


getOptionsMenu(wf) {
    const params = {
      name: 'workflows$stagesMenu$get',
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('audience'),
          profile: this.localStorage.getItem('profile'),
          idPerson:  this.localStorage.getItem('clientIdentifier')


        },
        path: {
           // workflowCode: this.transactionData?.plan?.
             workflowCode: wf


        },
        body: {}
      }
    };

    let sectionBack = null;

   this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
     let endSection    = null;
        if (result && result?.length > 0) {
          
          let transitionsWfs = [];

           for (const dataStage of result) {
            if (dataStage) {
                if (dataStage.howToSteps) {
                    for (const dataStep of dataStage.howToSteps) {

                        if (dataStep.sections) {

                          for (const dataSection of dataStep.sections) {

                            endSection = dataSection.alternateName;


                            if(this.sectionName == dataSection.alternateName){
                              this.dropDownTransitionsWfs[dataStep.alternateName] = true;

                            }


                          transitionsWfs[dataSection.alternateName]={};
                          transitionsWfs[dataSection.alternateName]['active'] = {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf} ;
                          

                          if(sectionBack){
                            
                            transitionsWfs[sectionBack?.section]['new'] = {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf } ;
                            transitionsWfs[dataSection.alternateName]['back'] = sectionBack;
                            sectionBack =  {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf};

                          }else{

                            sectionBack =  {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf};
                          
                          }
                        }

                        }

                    }
                }
            }

        }



        if( this.localStorage.getItem('redirectTransation') || this.optionsDataWF?.productCode){

          this.histotyBak = 'transaction-dashboard';
          transitionsWfs[endSection]['end'] = '/'+this.histotyBak;

        }else{
          this.histotyBak = 'client-data';

          transitionsWfs[endSection]['end'] = '/'+this.histotyBak;

        }



        
        this.workflowService.setWfTransitions(transitionsWfs);


        this.transitionsWfs =  transitionsWfs;
        this.workflowStatusMenu =  result;


        if(this.continueSection){
          //debugger
          this.setRedirectSectionSection(true);
        }



          this.resetStatus();
      

        }else{

          this.transitionsWfs = null;
           this.workflowStatusMenu = null;

        }



    });
   
  }



redirectHistotyBak( ) {

 //if(!localStorage?.getItem('redirectTransation')){

  let url = [this.histotyBak];
  const prefixed = '';
 

   let queryParamsResult = {
     role: this.localStorage.getItem('role'),
     profile: this.localStorage.getItem('profile'),
     audience: this.localStorage.getItem('audience'),
     transactionNumber: this.transactionData?.transactionNumber
   };


   const queryParams = queryParamsResult;
 
   
   this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });
    
  //}
  
 }


 redirectSection(urlWf, stage,step,section ) {

    this.dropDownTransitionsWfs[step] = true;
    const prefixed = 'private';
   let url = [urlWf + '/stages/' + stage + '/steps/' + step];

    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience'),
      section: section,

    };


    this.buttomModal = false;
    this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });

  }


 redirectSectionView(urlWf, stage,step,section ) {



let temp =(this. workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state;
let temp2;
   if(this.transitionsWfs[section?.alternateName]?.back){
     let dataBack = this.transitionsWfs[section?.alternateName]?.back;

        temp2 =(this. workflowStatus[dataBack?.stage])?.steps[dataBack?.step]?.sections[dataBack?.section]?.state;

   };

   

   ///:Todo
   if( temp == 'COMPLETED' ||  temp2 == 'COMPLETED' || this._auth.getAdminOrganizationIdentifier() ){

    this.nodeActive = section.alternateName
  this.dropDownTransitionsWfs[step?.alternateName] = true;
  const prefixed = 'private';
 let url = [urlWf + '/stages/' + stage?.alternateName + '/steps/' + step?.alternateName];

  const queryParams = {
    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    section: section?.alternateName,

  };


  this.buttomModal = false;
  this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });
}

}


  redirectReq(urlWf, stage,step ) {

    const prefixed = 'private';
   let url = [urlWf + '/stages/' + stage + '/steps/' + step];

    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience')
    };


    this.buttomModal = false;
    this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });

  }


resetStatus() {   
    let params;
  if( this.workflowCodeActive && !this.optionsDataWF?.productCode && this.optionsDataWF?.endpointStatus ){
      params = {
  
      name: this.optionsDataWF?.endpointStatus,
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('audience'),
          //id_person:  this.localStorage.getItem('clientIdentifier'),
          id_person:  this.localStorage.getItem('clientIdentifier'),

          wfp_code: this.workflowCodeActive

        },
        path: {
       
        },
        body: {}
      }
    };

  }else if( this.workflowCodeActive && this.optionsDataWF?.productCode && this.optionsDataWF?.endpointStatus ){


     params = {
  
      name: this.optionsDataWF?.endpointStatus,
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('audience')

        },
        path: {
            transactionNumber: this.transactionData?.transactionNumber,
            idPerson:this.transactionData?.holder?.identifier,
            workflowCode: this.workflowCodeActive

        },
        body: {}
      }
    };


  }
  
    if(this.workflowStatusSubscription){
      this.workflowStatusSubscription.unsubscribe();
    }

    if(params){
      
    this.workflowStatusSubscription =  this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
      let sectionBack = null;
      let sectionNext = null;

        if (result) {

         //   this._akeelaSidebarMenu.setshowSidebarLeft(true);
            let WorkflowStatus = [];
            let stepsStates;
            let sectionsStates;

            for (const dataStage of result) {
                if (dataStage) {

                    stepsStates = [];
                    if (dataStage.howToSteps) {
                        for (const dataStep of dataStage.howToSteps) {
                            sectionsStates = [];

                            if (dataStep.sections) {

                                for (const dataSection of dataStep.sections) {                                 
                                    sectionsStates[dataSection.alternateName] = {
                                        code: dataSection.alternateName,
                                        state: dataSection.status,
                                    };
                                }
                            }

                            stepsStates[dataStep.alternateName] = {
                                code: dataStep.alternateName,
                                state: dataStep.status,
                                sections: sectionsStates
                            };

                        }
                    }
                }


                WorkflowStatus[dataStage.alternateName] = {
                    code: dataStage.alternateName,
                    state: dataStage.status,
                    steps: stepsStates
                };

             

            }

                this.workflowStatus= WorkflowStatus;

                //&&  this.sectionName 

                this.workflowService.setWorkflowStatus(WorkflowStatus);
                if(this.workflowCodeActive && this.workflowCodeActive != 'ACCOUNT_OPENING' && this.workflowCodeActive != 'INTER_IDENTITY_VERIF'  ){

                  this.getworkflowCodeContinue(this.workflowCodeActive);

                  let sectionRedirect = this.localStorage.getItem('sectionRedirect');

                  
                  if(sectionRedirect){
                  setTimeout(() => {
                    this.localStorage.removeItem('sectionRedirect');
                    this.redirectSection(this.sectionInactive?.urlWf,this.sectionInactive?.stage,this.sectionInactive?.step,this.sectionInactive?.section);
                  });

                  }
                  
                }
          
              
        }
    });
   
  }

  }

  getworkflowCodeContinue(wf){


       let firstSection = null;
       let firstSectionData = null;
       this.sectionInactive = null;


    if (this.workflowStatusMenu) {
          
      for (const dataStage of this.workflowStatusMenu) {
        if (dataStage) {
            if (dataStage.howToSteps) {
                for (const dataStep of dataStage.howToSteps) {

                    if (dataStep.sections) {

                      for (const dataSection of dataStep.sections) {

                        if(dataSection?.additionalType != 'modal'){

                          if(!firstSection){
                            firstSectionData =  {urlWf:wf, stage:dataStage?.alternateName,step:dataStep?.alternateName,section: dataSection?.alternateName };

                          }

                          if((this.workflowStatus[dataStage?.alternateName])?.steps[dataStep?.alternateName]?.sections[dataSection?.alternateName]?.state != 'COMPLETED' && !this.sectionInactive){

                            this.sectionInactive =  {urlWf:wf, stage:dataStage?.alternateName,step:dataStep?.alternateName,section: dataSection?.alternateName };


                          }

                        }
                      

                     }

                    }

                }
            }
        }

    }
    if(!this.sectionInactive){
      this.sectionInactive = firstSectionData;
    }

  }

  return this.sectionInactive;
}


  openModalMenu() {
   this.animate_init_bottom = false;
    this.buttomModal = true;
  }

  getIfSection(section,data){


    if(this.transitionsWfs && this.transitionsWfs[section]){

      let resp =   this.transitionsWfs[section] ?   this.transitionsWfs[section][data] != null  : false;

      return true;
    }else{

      this.transitionsWfs;
      return false;
    }
  }






getIndicatorsProgres(trans) {

  let data :any;
  if(trans){

    let transactions = [];
        transactions = [...transactions, {transactionNumber: this.transactionData?.transactionNumber}]
    data = {
      "workflow":this.workflowCodeActive,
      bankAccountTransactions :transactions
    } ;

  }else{

    data = {
      "workflow":this.workflowCodeActive,
      "personId": this.localStorage.getItem('clientIdentifier')
  } ;


  }
 
    
 
  const params = {

    name: 'resume$workflow_progress$post',
    params: {
      request: {
        role: this.localStorage.getItem('role'),
        audience: this.localStorage.getItem('audience'),

      },
      path: {},
      body: data

    }
  };

  this.indicatorsLoading = true;
  let indicatorsProgres = {};
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    if (response) {
 
      let progress;
      let preData =response[0]?.modules;

      if(this.optionsDataWF?.productCode){
      let  progress2 = preData?.filter((resp) => {
          return resp.alternateName == this.workflowCodeActive;
        });
       progress  = progress2[0]?.progress;

      }else{
       progress = response[0]?.modules[0]?.progress;

      }
    

       let  maxValue = progress?.maxValue;
       let  minValue = progress?.minValue;

       indicatorsProgres['maxValue'] = maxValue;
       indicatorsProgres['minValue'] = minValue;
       indicatorsProgres['percent'] = minValue > 0 ?   this.round(minValue*100/maxValue):0;
        ;
       this.indicatorsProgres = indicatorsProgres;
        this.indicatorsLoading = false;
      }


  }, error => {

    this.indicatorsLoading = false;

  });

}



round(value) {
  return Math.round(value);
}






getDataWorkflows() {
  const params = {
    name: 'workflow$workflow$get',
    params: {
      request: {
        content:'none'
      },
      path: {
        "workflow":this.workflowCodeActive,
      },
      body: {}
    }
  };

  let sectionBack = null;

 this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
   let endSection    = null;
      if (result) {
        
        this.optionsDataWF = result;

        this.initWorkflowActive();


      }


  });
}


setRedirectSectionSection(mark?){

  if(mark){

    this.continueSection = false;

  }

  

  if(this.transitionsWfs){

    const res = this.transitionsWfs[ this.sectionName];
  if(res?.new){
    
    const newResp =  res?.new;




    if(mark){

      this.continueSection = false;
  
       console.clear();      console.log("");

      console.log("************ test ******************");
      console.log("");
      console.log("Section Actual: ",this.sectionName);
      console.log("new sections : ", newResp?.section);
      console.log("");
      console.log("OBJ completo");
      console.log(newResp);
      console.log("");
      console.log("************ fin test ******************");
      console.log("");
  
    }


      this.redirectSection( newResp?.wf,newResp?.stage, newResp?.step, newResp?.section);
 




  }else if(this.transitionsWfs[ this.sectionName]?.end){

     this.redirectToUrl(res?.end);

  }

}

}


redirectToUrl(url) {
  const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience'),
  };


this.router.navigate([url], { queryParams: queryParams });
}
 
}


