import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UploadService, ErrorService } from '@akeela/common';
import { AuthService } from '@akeela/auth';
import { HttpService } from '@akeela/properties';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertiesService } from '../../../../services/properties/properties.service';
import { take } from 'rxjs/operators';

import { WorkflowService } from '../../../../services/workflow.service';
import { TransitionService } from '../../../../services/transition.service';
import { I18nPipe } from '@akeela/i18n';
import swal from 'sweetalert2';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService, RouteUtilsService } from '@akeela/workflow';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
import { LocalService } from '@akeela/local-storage';
import { RequirementsService } from '@akeela/requirements';



@Component({
  selector: 'ak-requirements-briefcase',
  templateUrl: './requirements-briefcase.component.html',
  styleUrls: ['./requirements-briefcase.component.css']
})
export class RequirementsbriefcaseComponent implements OnInit, OnDestroy {
  config_requirements: any = null;
  config_requirements_other:any;
  balance_requirements = null;

  audience;
  idPerson;
  idPersonMarketType;
  user;
  submit_disabled;
  activeRequirements = false;

  workflow;
  stage;
  step;

  queryParams;

  mb_max_filesize: any = 1;

  role;
  filter_selected = '';
  filter_category_selected = '';
  categories;

  extensions;
  env: any;
  folder;

  isBeforeTransactionIncomplete = false;
  paramsSubscription: Subscription;
  start_requirements = false;
  rq_menu = 1;

  _dataInformation = null;

  @Input() set dataInformation(value) {
    this._dataInformation = value;
  }


  constructor(
    private _upload: UploadService,
    public _auth: AuthService,
    private _httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private _wfService: WorkflowService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private _error: ErrorService,
    private _transition: TransitionService,
    private i18n: I18nPipe,
    private _properties: PropertiesService,
    private localStorage: LocalService,
    private routerUtilService: RouteUtilsService,
    private _requirementsService: RequirementsService

  ) { }

  async ngOnInit() {
    this.env = environment;
    this.folder = '';
    if (environment.theme.images === 'FACEBANK') {
      this.folder = 'facebank/';
    } else if (environment.theme.images === 'MIBANCO') {
      this.folder = 'mibanco/';
    }

    await this.route.queryParams.pipe(take(1)).toPromise().then(res => {
      this.queryParams = res;
    });

    this.role = await this.routerUtilService.getParamAsync(this.route, 'role');

    this._properties.getProperty({
      endpointKey: 'app_properties$getProperty$get',
      propertyKey: 'akarchive.valid.extention'
    }).subscribe(resp => {
      this.extensions = resp;
      this.setConfigurationRequirements();
    });
    
    
  }



  setConfigurationRequirements() {
    this.start_requirements = false;
    setTimeout(()=>{
      this.config_requirements = {

        urlModal:'requirementsWindowContent',
        baseEndpoint:'person_requirement',
        downloadAllUploadFilesLink: true,
        downloadAllUploadFilesByRequirementLink: true,
        showHeaderContent: true,
        showFooterContent: false,
        showDetailHeaderContent: true,
        showDetailFooterContent: false,
        isReadonly: false,
        isPhysicalCheckActive: true,
        isDigitalUploadActive: true,
        isCameraActive: false,
        isHistoryResponsesShow: true,
        isFilterRequirementsByResponse: false,
        isFilterRequirementsByCategory: true,
        isShowStatusResponse: true,
        isInputDateExpiredActive: true,
        isActiveProgressBarUpload: false,
        isActiveVerifyChangeTransaction: true,
        isEnabledIconColors: true,
        isDetailEnable: false,
        isModeUserActive: false,
        internalComment: false,
        isEnableConsiderationsByCategory: true,
        isEnableConsiderationsByRequirements: true,
        isEnableFilterInconsistent: false,
        isEnableLogicDelete: false,
        enableEspecialUpload: {
          active: false,
          status: null
        },
        viewName: 'UPLOAD_FILES_EXAMPLE',
        filterRequirementsByResponse: {
          state: this.filter_selected
        },
        filterRequirementsByCategory: {
          name: this.filter_category_selected
        },
        file: {
          file_type_accepts: this._requirementsService.getPermitedExtensions(this.extensions),
          file_type_accepts_description: this._requirementsService.getPermitedExtensionsDescription(this.extensions),
          mb_max_filesize: this.mb_max_filesize
        },
        lists: {},
        endpoints: {
          downloadRequirementFilesZip: {
            name: 'requirements$zipfile_requirement$get',
            params: {
              request: {
                systemUser: this._auth.getUsername(),
                idTransactionRequirement: null
              },
              path: {
              },
              body: {}
            }
          },
          downloadAllFilesZip: {
            name: 'requirements$zipfile_transaction$get',
            params: {
              request: {
                systemUser: this._auth.getUsername()
              },
              path: {
               
              },
              body: {}
            }
          },

          getRequirements: {
            identifier: '',
            name: 'requirements$person_requirement$get',
            params: {
              request: {
                idPerson:this.localStorage.getItem('clientIdentifier'),
                audience:'NATURAL',
                others:false,
                typeOfPerson: this._dataInformation?.typeOfCompany?this._dataInformation?.typeOfCompany:null

              },
              path: {
              },
              body: {}
            }
          },
          
          saveRequirement: {
            name: 'requirements$person_requirement$get',
            params: {
              request: {
              
              },
              path: {},
              body: {
                'requirement': {
                  'identifier': null
                },
                'requirementArchives': [
                  {
                    'creator': {
                      'identifier': this._auth.getUsername()
                    }
                  }
                ],
                'person': {
                  identifier:this.localStorage.getItem('clientIdentifier'),
                  identity:"V-19163767",


                }
                
  
              }
            }
          },
          deleteArchive: {
            name: 'archive$archive$delete',
            params: {
              request: {},
              path: {},
              body: {
                identifier: null
              }
            }
          },
          deleteRequirement: {
            name: 'requirements$requirement$delete',
            params: {
              request: {},
              path: {},
              body: {
                alternateName: 'ACCREDITED',
                transaction: {
                
                },
                requirement: {
                  'identifier': null
                },
                person: {
                },
                requirementArchives: [{
                  identifier: null
                }]
              }
            }
          }
        
        }
      };

      this.config_requirements_other = {
        
        urlModal:'requirementsOtherWindowContent',
        baseEndpoint:'person_requirement',
        downloadAllUploadFilesLink: true,
        downloadAllUploadFilesByRequirementLink: true,
        showHeaderContent: true,
        showFooterContent: false,
        showDetailHeaderContent: true,
        showDetailFooterContent: false,
        isReadonly: false,
        isPhysicalCheckActive: true,
        isDigitalUploadActive: true,
        isCameraActive: false,
        isHistoryResponsesShow: true,
        isFilterRequirementsByResponse: false,
        isFilterRequirementsByCategory: false,
        isShowStatusResponse: true,
        isInputDateExpiredActive: true,
        isActiveProgressBarUpload: false,
        isActiveVerifyChangeTransaction: false,
        isEnabledIconColors: true,
        isDetailEnable: false,
        isModeUserActive: false,
        internalComment: false,
        isEnableConsiderationsByCategory: true,
        isEnableConsiderationsByRequirements: true,
        isEnableFilterInconsistent: false,
        isEnableLogicDelete: false,
        enableEspecialUpload: {
          active: false,
          status: null
        },
        viewName: 'UPLOAD_FILES_EXAMPLE',
        filterRequirementsByResponse: {
          state: this.filter_selected
        },
        filterRequirementsByCategory: {
          name: this.filter_category_selected
        },
        file: {
          file_type_accepts: this._requirementsService.getPermitedExtensions(this.extensions),
          file_type_accepts_description: this._requirementsService.getPermitedExtensionsDescription(this.extensions),
          mb_max_filesize: this.mb_max_filesize
        },
        lists: {},
        endpoints: {

          getOtherRequirements: {
            identifier: '',
            name: 'requirements$person_requirement_other$get',
            params: {
              request: {
                idPerson:this.localStorage.getItem('clientIdentifier'),
                audience:'NATURAL',
                personDocument:true

              },
              path: {
              },
              body: {}
            }
          },
          downloadRequirementFilesZip: {
            name: 'requirements$zipfile_requirement$get',
            params: {
              request: {
                systemUser: this._auth.getUsername(),
                idTransactionRequirement: null
              },
              path: {
              },
              body: {}
            }
          },
          downloadAllFilesZip: {
            name: 'requirements$zipfile_transaction$get',
            params: {
              request: {
                systemUser: this._auth.getUsername()
              },
              path: {
               
              },
              body: {}
            }
          },

          getRequirements: {
            identifier: '',
            name: 'requirements$person_requirement$get',
            params: {
              request: {
                idPerson:this.localStorage.getItem('clientIdentifier'),
                audience:'NATURAL',
                others:true,
                typeOfPerson: this._dataInformation?.typeOfCompany?this._dataInformation?.typeOfCompany:null
              },
              path: {
              },
              body: {}
            }
          },
          
          saveRequirement: {
            name: 'requirements$person_requirement$get',
            params: {
              request: {},
              path: {},
              body: {
                'requirement': {
                  'identifier': null
                },
                'requirementArchives': [
                  {
                    'creator': {
                      'identifier': this._auth.getUsername()
                    }
                  }
                ],
                'person': {
                  identifier:this.localStorage.getItem('clientIdentifier'),
                  identity:"V-19163767",

                }
  
              }
            }
          },
          deleteArchive: {
            name: 'archive$archive$delete',
            params: {
              request: {},
              path: {},
              body: {
                identifier: null
              }
            }
          },
          deleteRequirement: {
            name: 'requirements$requirement$delete',
            params: {
              request: {},
              path: {},
              body: {
                alternateName: 'ACCREDITED',
                transaction: {
                
                },
                requirement: {
                  'identifier': null
                },
                person: {
                },
                requirementArchives: [{
                  identifier: null
                }]
              }
            }
          }
        
        }
      };
      this.start_requirements = true;
      this.activeRequirements = true;

    }, 1000);

    
    
    
  }

  response_transaction_change(newidentifier) {
    this.activeRequirements = false;
    this._wfService.showContainer$.next(true);
  }

  show_file(file) {
    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: null
      }
    };

    if (file.dateCreated) {
      archive['dateCreated']['orderDate'] = file.dateCreated.modifiedDate;
    } else {
      archive['dateCreated']['orderDate'] = null;
    }

    if (archive.identifier !== null) {
      this._upload.visor_params.name = '';
      this._upload.visor_params.date = '';
      this._upload.visor_params.endpoint = 'archive$downloadFile$get';
      this._upload.visor_params.archive = archive;
      this._upload.visor_params.deleteAfterShow = '';
      if (file['action'] === 'download') {
        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } if (file['action'] === 'downloadzip') {

        if (this.env.theme.images === 'FACEBANK') {
          this._upload.visor_params.endpoint = 'archive$downloadZipFile$get';
        }

        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } else {
        this._upload.visor_params.action = '';
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      }
    }
  }


  response_requirements(response) {
    console.log(response);
    this.balance_requirements = response;
    this.akWorkflowService.worflowEvents$.next({
      response: 'UPDTATE REQUIREMENT',
      action: null,
      forms: null
    });
  }

  finish_upload(response) {
    this.akWorkflowService.worflowEvents$.next({
      response: 'UPDATE_REQUIREMENT_FINISH',
      action: null,
      forms: null
    });
  }

  round(value) {
    return Math.round(value);
  }


  goToPaperwork() {
    this.router.navigateByUrl(
      this.router.createUrlTree(['private/' + environment.workflow + '/stages/LIST_ACCOUNT_OPENING/steps/LIST_ACCOUNT_OPENING'],
        {
          queryParams: {}
        }
      )
    );
  }

  openWindowRequirement(open) {
    //debugger
    this._wfService.showContainer$.next(!open);
  }

  continuar() {
    this.router.navigateByUrl(
      this.router.createUrlTree(['private/' + environment.workflow + '/stages/ACCOUNT_CONTRACT/steps/ACCOUNT_CONTRACT'],
        {
          queryParams: this.queryParams
        }
      )
    );
  }

  send() {
    const params = {
      name: 'transaction$analysisdocsupports$put',
      params: {
        request: {
          role: this.localStorage.getItem('role'),
         // audience: this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL'
        },
        path: {
          transactionNumber: null,
          workflow: this.workflow,
          stage: this.stage,
          step: this.step,
          event: 'SEND_TRANSACTION_ANALYSIS_DOC_SUPPORT'
        },
        body: {}
      }
    };

    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this._transition.nextStep('private', response.nextStep);
    }, (error: any) => {
      this._error.show(error);
    });

  }

  response_transaction(transaction) {
    this._transition.nextStep('private', transaction.nextStep);
  }


  ngOnDestroy() {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  showDetail() {
    this.config_requirements.isDetailEnable = !this.config_requirements.isDetailEnable;
  }

  filter_category(event) {
    if (this.filter_category_selected !== event.target.value) {
      this.activeRequirements = false;
      this.filter_category_selected = event.target.value;
      setTimeout(() => {
        if (event.target.value !== '') {
          this.config_requirements.filterRequirementsByCategory = {
            name: event.target.value
          };
        } else {
          this.config_requirements.filterRequirementsByCategory = {
            name: ''
          };
        }
        this.activeRequirements = true;
      }, 30);
    }
  }

  filter(event) {

    if (this.filter_selected !== event.target.value) {
      this.activeRequirements = false;
      this.filter_selected = event.target.value;
      setTimeout(() => {
        if (event.target.value !== '') {
          this.config_requirements.filterRequirementsByResponse = {
            state: event.target.value
          };
        } else {
          this.config_requirements.filterRequirementsByResponse = {
            state: ''
          };
        }
        this.activeRequirements = true;
      }, 30);
    }
  }


  getRequirementsCategory(idPlan, audience, withRequirements?) {
    return new Observable(resolve => {
      let request;
      if (withRequirements) {
        request = {
          'audience': audience,
          'withRequirements': withRequirements
        };
      } else {
        request = {
          'audience': audience
        };

      }

      const params: any = {
        endpoint: {
          name: 'requirements$category$get',
          params: {
            path: {
              'idPlan': idPlan
            },
            request: request,
            body: {}
          }
        }
      };
      this._httpService.executeRequest(params.endpoint.name, params.endpoint.params, true).subscribe((response: any) => {
        resolve.next(response);
        resolve.complete();
      }, (error: any) => {
        resolve.next(null);
        resolve.complete();
      });
    });
  }

}
