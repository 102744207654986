export const lang: any = {
  'label.section.summaryAccountOpening.name': '',
  'label.section.listTransactions.name': '',
  'label.section.confirmationUserAccountAccountOpening.name': '',
  'label.section.ListAccountOpening.name': '',
  'label.description.summaryAccountOpening': '',
  'VERIFIED': 'Verificado',
  'Summary Transaction': 'Resumen',
  'REJECTED': 'Rechazado',
  'INCONSISTENT': 'Inconsistente',
  'INCOMPLETED': 'Devuelto',
  'Document Support': 'Recaudos',
  'document support': 'Recaudos',
  '@i18n-error-user-exceed-fails-is-locked-and-required-manual-unlocking': 'Estimado cliente, ha excedido el límite de intentos de recuperación de contraseña, debe comunicarse con nosotros o dirigirse a la oficina más cercana para realizar el desbloqueo',
  '@i18n-error-user-is-locked-and-required-manual-unlocking': 'Su usuario no pudo ser desbloqueado. Favor de comunicarse con su facilitador de cuenta.',
  '@i18n-error-user-is-not-locked': 'El usuario no se encuentra bloqueado',
  '@requirements-text-responses': 'Respuestas',
  '@i18n-login-error-user-restricted-text': 'Estimado cliente, por razones de seguridad su usuario no pudo ser desbloqueado a través de la página. Para desbloquearlo debe acudir a su agencia más cercana',
  '@i18n-login-error-user-restricted': 'Usuario restringido',
  '@i18n-login-error-user-inactive-text': 'Revise su correo y haga click en él link de verificación.',
  '@i18n-login-error-user-inactive': 'El usuario se encuentra inactivo',

  '@i18n-login-error-user-block-text': 'Ha superado el límite de intentos fallidos, por razones de seguridad su usuario fue bloqueado.',
  '@i18n-login-error-user-block': '',
  '@i18n-login-error-user-bad-credentials': 'Mensaje',
  '@i18n-login-error-text-login': 'Favor, intente nuevamente.',
  '@i18n-login-error-login': 'Error al iniciar sesión',
  '@i18n-login-error-bad-credentials-text': 'Usuario y/o contraseña inválida',
  '@i18n-error-user-unauthorized': 'No autorizado',
  '@i18n-error-user-required-manual-unlocking': 'Se ha excedido la cantidad de intentos de desbloqueo, favor comuníquese con nosotros para realizar el desbloqueo de su usuario',
  '@i18n-error-user-has-already-login': 'El usuario ya se encuentra conectado desde otro computador',
  '@i18n-question-user-has-already-logged-in': 'Tiene otra sesión activa ¿Desea continuar con esta nueva sesión e inhabilitar la sesión anterior?',
  '@i18n-error-unexpected-error': 'Se ha presentado un error inesperado',
  '@i18n-error-somethings-wrong': 'Algo salió mal!',
  '@i18n-error-server-internal-error': 'Mensaje',
  '@i18n-error-missed-his-second-attempt-login': 'Segundo intento de inicio de sesión inválido, al tercer intento fallido El usuario será bloqueado por seguridad',
  '@i18n-error-invalid-username-suplied': 'El usuario ingresado es inválido',
  '@i18n-error-invalid-email-suplied': 'El correo ingresado es inválido',
  '@i18n-error-badrequest': 'Mensaje',
  '@i18n-error-bad-request-label': 'Estimado cliente:',
  '@i18n-error-bad-request': 'Mensaje',
  '@collections-text-expiration': 'Expiración',
  '@collections-text-emission': 'Emisión',
  '@collections-text-apply': 'Aplicar',
  '@i18n-validation-have-to-add-pep': 'Debe agregar la(s) Persona(s) Políticamente Expuesta(s) con la(s) que tiene relación, de lo contrario, indique que no posee relación con Personas Políticamente Expuestas',
  '@i18n-error-message-generic': 'Ha ocurrido un error Inésperado',
  '@i18n-error-title-generic': 'Error',
  '@i18n-text-mail-success-recovery-password-client': 'Recuperación de contraseña exitosa',
  '@i18n-error-message-shareholders-percentage': 'El porcentaje asignado del accionista excede en la suma del 100%',
  '@i18n-error-title-shareholders-percentage': '',
  '@i18n-error-message-shareholders-nopep': 'Estimado usuario, está indicando que está relacionado con peps pero no agregó ningún pep relacionado',
  '@i18n-error-title-shareholders-nopep': '',
  '@i18n-error-message-legal-not-found': 'Persona jurídica relacionada no encontrada',
  '@i18n-error-title-legal-not-found': '',
  '@i18n-error-message-fwrapper-null': 'El figureWrapper no puede ser nulo',
  '@i18n-error-title-fwrapper-null': '',
  '@i18n-error-message-fwrapper-p-o-null': 'FigureWrapper.person y figureWrapper.organization no pueden ser nulos',
  '@i18n-error-title-fwrapper-p-o-null': '',
  '@i18n-error-message-tran-number-null': 'El TransactionNumber no puede ser nulo',
  '@i18n-error-title-tran-number-null': '',
  '@i18n-error-message-wf-pc-null': 'El WorkflowProcessCode no puede ser nulo',
  '@i18n-error-title-wf-pc-null': '',
  '@i18n-error-message-stage-code-null': 'El código de stage no puede ser nulo',
  '@i18n-error-title-stage-code-null': '',
  '@i18n-error-message-event-code-null': 'El código de evento no puede ser nulo',
  '@i18n-error-title-event-code-null': '',
  '@i18n-error-message-step-code-null': 'El código de step no puede ser nulo',
  '@i18n-error-title-step-code-null': '',
  '@i18n-error-message-role-null': 'El rol no puede ser nulo',
  '@i18n-error-title-role-null': '',
  '@i18n-error-message-audience-null': 'La audiencia no puede ser nula',
  '@i18n-error-title-audience-null': '',
  '@i18n-error-message-rtype-atype-null': 'relatedType.additionalType(código) no puede ser nulo',
  '@i18n-error-title-rtype-atype-null': '',
  '@i18n-error-message-rto-null': 'relatedTo no puede ser nulo',
  '@i18n-error-title-rto-null': '',
  '@i18n-error-message-rto-id-null': 'relatedTo.identifier no puede ser nulo o vacío',
  '@i18n-error-title-rto-id-null': '',
  '@i18n-error-message-rto-atype-null': 'relatedTo.additionalType no puede ser nulo',
  '@i18n-error-title-rto-atype-null': '',
  '@i18n-error-message-rid-null': 'relatedId no puede ser nulo',
  '@i18n-error-title-rid-null': '',
  '@i18n-error-message-rtype-null': 'relatedType no puede ser nulo',
  '@i18n-error-title-rtype-null': '',
  '@i18n-error-message-idap-null': 'idAccountPerson no puede ser nulo',
  '@i18n-error-title-idap-null': '',
  '@i18n-error-message-obat-null': 'oldBankAccountTransaction no puede ser nulo',
  '@i18n-error-title-obat-null': '',
  '@i18n-error-message-tsp-null': 'totalSharePercentage no puede ser nulo',
  '@i18n-error-title-tsp-null': '',
  '@i18n-error-not-found': 'no existe',
  '@i18n-error-related-person-id': 'persona relacionada con id=',
  '@i18n-error-related-natural-id': 'persona relacionada del tipo natural con id=',
  '@i18n-error-related-type-code': 'persona relacionada con código',
  '@i18n-error-related-legal-id': 'persona relacionada del tipo jurídica con id=',
  '@i18n-error-message-audience-nomatch': 'La audiencia no coincide con el objeto en FigureWrapper',
  '@i18n-error-title-audience-nomatch': '',
  '@i18n-error-transaction-number': 'transacción con número',
  '@i18n-error-message-sys-user-null': 'el systemUser no puede ser nulo',
  '@i18n-error-title-sys-user-null': '',
  '@i18n-error-message-holder-id-null': 'el idHolder no puede ser nulo',
  '@i18n-error-title-holder-id-null': '',
  '@i18n-error-not-sales-manager': 'no es gerente de ventas',
  '@i18n-error-message-account-not-found': 'cuenta no existe',
  '@i18n-error-title-account-not-found': '',
  '@i18n-error-acctran-number': 'cuenta de transactionNumber ',
  '@i18n-error-message-account-null': 'la cuenta no puede ser nula',
  '@i18n-error-title-account-null': '',
  '@i18n-error-message-codestate-null': 'el codeState no puede ser nulo',
  '@i18n-error-title-codestate-null': 'cuenta no existe',
  '@i18n-error-message-locs-name-null': '',
  '@i18n-error-title-locs-name-null': 'loanOrCreditStatus.name no puede ser nulo',
  '@i18n-error-message-locs-name-empty': '',
  '@i18n-error-title-locs-name-empty': 'loanOrCreditStatus.name está vacío',
  '@i18n-error-message-locs-mu-null': '',
  '@i18n-error-title-locs-mu-null': 'loanOrCreditStatus.modifyUser no puede se nulo',
  '@i18n-error-message-aud-pmt-null': '',
  '@i18n-error-title-aud-pmt-null': 'Audience (personMarketType) no puede ser nulo',
  '@i18n-error-message-account-penstate': '',
  '@i18n-error-title-account-penstate': 'Solo puede dar respuesta a un tramite con estatus pendiente',
  '@i18n-error-message-docs-inconsistent': '',
  '@i18n-error-title-docs-inconsistent': 'Todos los documentos deben tener una respuesta y la respuesta debe ser diferente de inconsistente',
  '@i18n-error-message-invalid-resp-as': 'Respuesta inválida para el estatus de la transacción',
  '@i18n-error-title-invalid-resp-as': '',
  '@i18n-error-pers-org-id': 'Person or organization con id=',
  '@i18n-error-acc-ope-id': 'Account opening con id=',
  '@i18n-error-transaction-id': 'Transaction con id=',
  '@i18n-error-section-id-coma': ', section con id=',
  '@i18n-error-and-id-person': 'y idPerson=',
  '@i18n-error-message-id-greater-zero': 'id debe ser mayor a cero',
  '@i18n-error-title-id-greater-zero': '',
  '@i18n-error-acc-lp-id': 'Account Legal Person con id=',
  '@i18n-error-comp-reg-id': 'El company registration con id=',
  '@i18n-error-acc-per-id': 'Account Person for account con id=',
  '@i18n-error-message-org-null': 'Organization no puede ser nulo',
  '@i18n-error-title-org-null': '',
  '@i18n-error-message-alp-null': 'Account Legal Person no puede ser nulo',
  '@i18n-error-title-alp-null': '',
  '@i18n-error-message-pa-ct-null': 'El postalAddress.contactType() no puede ser nulo',
  '@i18n-error-title-pa-ct-null': '',
  '@i18n-error-message-person-null': 'la persona no puede ser nula',
  '@i18n-error-title-person-null': '',
  '@i18n-error-message-comp-reg-null': 'la companyRegistration no puede ser nula',
  '@i18n-error-title-comp-reg-null': '',
  '@i18n-error-message-lp-null': 'Legal Person no puede ser nula',
  '@i18n-error-title-lp-null': '',
  '@i18n-error-message-org-address-null': 'la organization.address no puede ser nulo',
  '@i18n-error-title-org-address-null': '',
  '@i18n-error-message-org-cp-null': 'El organization.contactPoint no puede ser nulo',
  '@i18n-error-title-org-cp-null': '',
  '@i18n-error-message-org-rd-null': 'El organization.registryData no puede ser nulo',
  '@i18n-error-title-org-rd-null': '',
  '@i18n-error-message-holder-public-null': 'El titular es una organización pública y se requieren estos datos: número de gaceta, afilición, codigo ONT y fecha de la gaceta.',
  '@i18n-error-title-holder-public-null': '',
  '@i18n-error-message-org-noe-null': 'El idTransaction no puede ser nulo',
  '@i18n-error-title-org-noe-null': '',
  '@i18n-error-message-tran-id-null': 'El constitution date must be before the modification date',
  '@i18n-error-title-tran-id-null': '',
  '@i18n-error-message-date-const-modif': '',
  '@i18n-error-title-date-const-modif': '',
  '@i18n-error-message-islr-year': 'El año de la declaración del ISRL no es válido',
  '@i18n-error-title-islr-year': '',
  '@i18n-error-message-tran-has-ap': 'La transacción ya posee una persona vinculada',
  '@i18n-error-title-tran-has-ap': '',
  '@i18n-error-holder-invalid-ptype': 'el dueño no es una persona natural o jurídica. personType=',
  '@i18n-error-message-transition-not-found': 'transacción no existe',
  '@i18n-error-title-transition-not-found': '',
  '@i18n-error-message-bat-null': 'bankAccountTransaction no puede ser nulo',
  '@i18n-error-title-bat-null': '',
  '@i18n-error-message-bat-id-null': 'bankAccountTransaction.identifier no puede ser nulo',
  '@i18n-error-title-bat-id-null': '',
  '@i18n-error-message-ast-name-null': 'actionStatusType.name (SectionCode) no puede ser nulo',
  '@i18n-error-title-ast-name-null': '',
  '@i18n-error-message-ast-aname-null': 'actionStatusType.alternateName (step_code) no puede ser nulo',
  '@i18n-error-title-ast-aname-null': '',
  '@i18n-error-message-ast-status-null': 'actionStatusType.status no puede ser nulo',
  '@i18n-error-title-ast-status-null': '',
  '@i18n-error-message-section-id-null': 'El idSection no puede ser nulo',
  '@i18n-error-title-section-id-null': '',
  '@i18n-error-message-person-id-null': 'El idPerson no puede ser nulo',
  '@i18n-error-title-person-id-null': '',
  '@i18n-error-message-account-id-null': 'El IdAccount no puede ser nulo',
  '@i18n-error-title-account-id-null': '',
  '@i18n-error-message-bat-holder-id-null': 'bankAccountTransaction.holder.identifier no puede ser nulo',
  '@i18n-error-title-bat-holder-id-null': '',
  '@i18n-error-cant-complete': 'no puede ser completado',
  '@i18n-error-step': 'El step',
  '@i18n-error-credit': 'El crédito',
  '@i18n-error-account': 'la cuenta',
  '@i18n-error-no-credit-father': 'no tiene crédito padre',
  '@i18n-error-acc-ope-number': 'El account opening de número',
  '@i18n-error-no-state-reg': 'no posee estado registrado',
  '@i18n-error-message-inc-sec-holder': 'Secciones incompletas para el titular: a la transacción le falta información',
  '@i18n-error-title-inc-sec-holder': '',
  '@i18n-error-username': 'El usuario',
  '@i18n-error-not-analyst': 'no es análista',
  '@i18n-error-message-plan-greater-zero': 'Plan debe ser mayor a cero',
  '@i18n-error-title-plan-greater-zero': '',
  '@i18n-error-acc-ope-tnumber': 'El account opening con transactionNumber',
  '@i18n-error-acc-taken-id': 'El account taken con id= ',
  '@i18n-error-message-no-account-avaliable': 'No hay cuenta disponible',
  '@i18n-error-title-no-account-avaliable': '',
  '@i18n-error-message-no-tc': 'No hay términos y condiciones activos',
  '@i18n-error-title-no-tc': '',
  '@i18n-error-no-holder': ' no tiene títular',
  '@i18n-error-message-plan-null': '',
  '@i18n-error-title-plan-null': 'El plan no puede ser nulo',
  '@i18n-error-message-plan-id-null': '',
  '@i18n-error-title-plan-id-null': 'El plan.identifier no puede ser nulo ó vacío',
  '@i18n-error-message-token-null': '',
  '@i18n-error-title-token-null': 'El token no puede ser nulo',
  '@i18n-error-message-username-null': '',
  '@i18n-error-title-username-null': 'El username no puede ser nulo ó vacío',
  '@i18n-error-message-all-queue-null': '',
  '@i18n-error-title-all-queue-null': 'Los argumentos de queue no puede ser nulo',
  '@i18n-error-message-both-flags-null': '',
  '@i18n-error-message-ihnrc-null': 'Both flags no puede ser nulo',
  '@i18n-error-title-ihnrc-null': '',
  '@i18n-error-acc-ope-not-mod': 'El account opening no puede ser modificado en',
  '@i18n-error-state': 'state',
  '@i18n-error-message-acc-cant-taken': 'No se puede tomar la cuenta porque no está pendiente',
  '@i18n-error-title-acc-cant-taken': 'La cuenta ya está tomada por otro usuario en el sistema',
  '@i18n-error-message-acc-taken': '',
  '@i18n-error-title-acc-taken': 'Para enviar la cuenta a análisis, no puede estar en ',
  '@i18n-error-account-has': 'La cuenta tiene',
  '@i18n-error-acc-sections-missing': 'secciones activas para el titular: falta información en la cuenta',
  '@i18n-error-message-up-docs-analysis': 'Debe cargar todos los documentos antes de enviarlos al análisis',
  '@i18n-error-title-up-docs-analysis': '',
  '@i18n-error-message-requirements-already-has-recent-active-response': 'El recaudo ya posee una respuesta activa',
  '@i18n-error-title-requirements-already-has-recent-active-response': '',
  '@i18n-error-message-requirements-person-and-organization-are-null': 'la persona asociada con el requisito no se conoce: transactionRequirement.person y transactionRequirement.organization son nulos',
  '@i18n-error-title-requirements-person-and-organization-are-null': '',
  '@i18n-error-message-requirements-document-not-must-have-response-or-response-incomplete': 'El documento no debe tener respuesta o la respuesta debe estar incompleta ó rechazada',
  '@i18n-error-title-requirements-document-not-must-have-response-or-response-incomplete': '',
  '@i18n-error-message-requirements-expiration-date-must-be-greater-equal-emission-date': 'La fecha de vencimiento debe ser mayor o igual que la fecha de emisión',
  '@i18n-error-title-requirements-expiration-date-must-be-greater-equal-emission-date': '',
  '@i18n-error-message-requirements-status-of-transaction-does-not-allow-operation': 'El estado de la transacción no permite esta operación',
  '@i18n-error-title-requirements-status-of-transaction-does-not-allow-operation': '',
  '@i18n-error-message-requirements-status-must-be-active-or-inactive': '',
  '@i18n-error-title-requirements-status-must-be-active-or-inactive': '',
  '@i18n-error-message-requirements-active-status-credit-isnt-pending-in-doc-supp-module': '',
  '@i18n-error-title-requirements-active-status-credit-isnt-pending-in-doc-supp-module': '',
  '@i18n-error-message-requirements-active-response-has-no-response-date': '',
  '@i18n-error-title-requirements-active-response-has-no-response-date': '',
  '@i18n-error-message-requirements-active-state-has-no-start-date': 'El estado activo no tiene fecha de inicio',
  '@i18n-error-title-requirements-active-state-has-no-start-date': '',
  '@i18n-error-is-null': 'es nulo',
  '@i18n-error-is-empty': 'es vacío',
  '@i18n-error-archive-id': 'archivo con id',
  '@i18n-error-and-requirement-id': ' y recaudo con id',
  '@i18n-error-requirement-id': 'recaudos con id',
  '@i18n-error-identifier-can-not-be-null': 'identificador no puede ser nulo',
  '@i18n-error-file-is-null': 'el archivo es nulo',
  '@i18n-error-transaction-requirement-with-id-equal': 'recaudo con id igual a ',
  '@i18n-error-identifier-is-null': 'el identificador es nulo',
  '@i18n-error-second-identifier-is-null': 'el segundo identificador es nulo',
  '@i18n-error-is-null-or-invalid-value': 'el valor es nulo o inválido',
  '@i18n-error-with_code': 'con código',
  '@i18n-error-with': 'con',

  '@i18n-error-message-bank-already-already-exists-an-account-number': 'Ya existe el número de cuenta',
  '@i18n-error-message-bank-for-person-with-id': ' para la persona',
  ' @i18n-error-and ': ' y ',
  '@i18n-error-message-bank-transaction-with-number': ' el número de transacción',

  '@i18n-error-message-bad-credentials-exceeded': 'Se ha excedido en la cantidad de respuestas incorrectas',
  '@i18n-error-message-bad-credencials-exceeded': 'Se ha excedido en la cantidad de respuestas incorrectas',

  '@i18n-error-message-invalid-username': 'El usuario no existe. Intente nuevamente.',
  '@i18n-error-message-user-not-found': 'El usuario no existe',
  '@i18n-error-message-natural-person-is-not-found-with-passport': '',
  '@i18n-error-message-natural-person-is-not-found-with-driver-license': '',
  '@i18n-error-message-natural-person-is-not-found-with-security-social-number': '',
  '@i18n-error-message-natural-person-is-not-found-with-other-identity': '',
  '@i18n-error-message-natural-person-is-not-found-with-identity': '',
  '@i18n-error-message-natural-person-is-not-found-with-tax-registration-number': '',
  '@i18n-error-message-natural-person-is-not-found-with-social-security-number': '',
  '@i18n-error-message-password-and-confirmation-must-be-the-same': 'la contraseña y su confirmación deben coincidir',
  '@i18n-error-message-username-already-registered': 'El usuario ya se encuentra registrado',
  '@i18n-error-message-email-already-registered': 'el correo ya está registrado',
  '@i18n-error-message-user-already-confirmed-email': 'ya El usuario confirmó su correo',
  '@i18n-error-message-must-be-graeter-than-zero': 'debe ser mayor a cero',
  '@i18n-error-message-user-does-not-have-client-role': 'El usuario no posee rol de cliente',
  '@i18n-error-message-invalid-systemuser': 'usuario del sistema inválido',
  '@i18n-error-message-user-have-no-active-profiles': 'El usuario no posee perfiles activos',
  '@i18n-error-message-is-not-a-systemyser': 'no es un usuario del sistema',
  '@i18n-error-message-systemyser-not-found': 'usuario del sistema no existe',
  '@i18n-error-message-profile-not-found': 'el perfil no existe',
  '@i18n-error-message-profile-with-code': 'perfil con código',
  '@i18n-error-message-profile-is-already-acitve-by-user': 'ya está activo por El usuario',
  '@i18n-error-message-is-not-sales-manager': 'no es un gerente de ventas',
  '@i18n-error-message-user-have-password': 'El usuario ya posee password',
  '@i18n-error-message-user-is-inactive': 'El usuario se encuentra inactivo',
  '@i18n-error-message-user-has-no-confirmed-email': 'El usuario no ha confirmado su correo, se ha enviado nuevamente su correo de confirmación',
  '@i18n-error-message-user-is-locked-require-manual-unlocking': 'El usuario require de desbloqueo manual',
  '@i18n-error-message-user-is-locked': 'El usuario se encuentra bloqueado',
  '@i18n-error-message-user-has-already-logged-in': 'El usuario ya se encuentra logueado',
  '@i18n-error-message-invalid-role-for-user': 'El perfil es inválido para el usuario',
  '@i18n-error-message-bad-credentials-second-attempt': 'Usuario y/o contraseña inválida, al próximo intento fallido su usuario será bloqueado',
  '@i18n-error-message-bad-credentials': 'Usuario y/o contraseña inválida',


  '@i18n-error-message-you-missed-the-attempt-number ': 'Respuesta inválida, ',
  '@i18n-error-message-you-missed-the-attempt-number-second-text': 'Respuesta invalida. Intente nuevamente. (Segundo intento inválido)',


  '@i18n-error-message-invalid-answer-supplied': 'Respuesta invalida. Intente nuevamente.',
  '@i18n-error-message-invalid-email': 'El correo no existe. Intente nuevamente.',
  '@i18n-error-message-user-is-not-locked': 'El usuario no se encuentra bloqueado',

  '@i18n-error-message-numeric-code-not-found-with-username': 'el código númerico no corresponde al usuario',
  '@i18n-error-message-numeric-code-expired': 'el código númerico expiró',
  '@i18n-error-invalid-status': 'estatus inválido',
  '@i18n-error-year': 'año',
  '@i18n-error-month': 'mes',
  '@i18n-error-message-bank-not-found': 'banco no existe',
  '@i18n-error-message-bank-movement-of-month': 'movimiento bancario del mes',
  '@i18n-error-message-bank-movement-already-registered-month-year': 'el movimiento bancario ya registrado para el mes y año',
  '@i18n-error-message-bank-account-number': 'cuenta bancaria de número',
  '@i18n-error-message-bank-movement-with-account-id': 'movimiento bancario con cuenta de id',
  '@i18n-error-message-bank-banking-movements-list': 'lista de movimientos bancarios',
  '@i18n-error-are-null': 'es null',
  '@i18n-error-message-invalid-file': 'archivo inválido',
  '@i18n-error-message-io-problem': 'problema de entrada/salida de datos',
  '@i18n-error-message-extensions-of-document-are-is-allowed': 'La extensión del documento no es válida',
  '@i18n-error-message-archive-is-not-found': 'el archivo no existe',
  '@i18n-error-message-could-not-create-directory-where-file-will-be-stored': 'no se puede crear el directorio para guardar el archivo',
  '@i18n-error-message-file-not-found': 'el archivo no existe',
  '@i18n-error-message-notification-not-found': 'notificación no existe',
  '@i18n-error-validation-failed': 'validación fallida',
  '@i18n-error-parameter_missing': 'parametro vacío',
  '@i18n-error-should-be-type': 'deberia ser del tipo',
  '@i18n-error-no-handler-found-for': 'manejador encontrado para',
  '@i18n-error-is-null-or-empty': 'es nulo o vacío',
  '@i18n-error-message-transaction-audience-is-not-match-with-object-figurewrapper': 'la audiencia de la transacción no coincide con el objeto figureWrapper',
  '@i18n-error-message-transaction-not-found': 'la transacción no existe',
  '@i18n-error-message-only-account-can-be-answered-in-pending-status': 'solo se pueden responder trámites con status pendiente',
  '@i18n-error-message-all-documents-must-have-a-different-answer': 'todos los documentos deben poseer respuesta',
  '@i18n-error-message-invalid-response-for-account-state': 'respuesta inválida para el estatus de la cuenta',
  '@i18n-error-message-to-send-transaction-to-analysis-must-have': 'para enviar la transacción a análisis debe tener',
  '@i18n-error-message-must-upload-all-documents-before': 'primero debe cargar todos los documentos',
  '@i18n-error-message-account-opening-not-found': 'la apertura de cuenta no existe',
  '@i18n-error-message-credit-has-no-credit-father': 'el crédito no posee cŕedito padre',
  '@i18n-error-message-account-opening-not-have-state-registered': 'el trámite no posee estatus registrado',
  '@i18n-error-message-incomplete-sections-for-the-holder': 'secciones incompletas para el poseedor',
  '@i18n-error-message-account-opening-can-not-be-modified': 'el trámite no puede ser modificado',
  '@i18n-error-message-account-dont-have-holder': 'la transacción no posee dueño',
  '@i18n-error-message-username-is-not-analyst': 'El usuario no es análista',
  '@i18n-error-message-account-cant-be-taken-because-isnt-pending': 'la transacción no puede ser tomada porque no se encuentra pendiente',
  '@i18n-error-message-account-is-taken-by-another-system-user': 'la transacción ya se encuentra tomada por otro usuario',
  '@i18n-error-message-all-arguments-of-queue-are-null': 'todos los argumentos del queue son nulos',
  '@i18n-error-message-account-not-available': 'la transacción no es accesible',
  '@i18n-error-message-for-send-transaction-to-analysis-can-not-be-state': 'para enviar la transacción a análisis no puede estar en estatus',
  '@i18n-error-message-account-has': ' la cuenta tiene ',
  '@i18n-error-message-account-has-two': 'la cuenta tiene dos',
  '@i18n-error-message-there-are-no-active-terms-and-conditions': 'no posee activo los terminos y condiciones',
  '@i18n-error-message-module-not-found': 'modulo no existe',
  '@i18n-error-message-environment-not-found': 'environment no existe',

  '@i18n-text-resend-mail-recovery': 'Se ha enviado un nuevo código a su correo electrónico.',
  '@i18n-text-send-notification': '',
  '@i18n-text-invalid-code': 'Código Inválido',
  '@i18n-text-invalid-code-message': 'Estimado cliente, el código ingresado no es válido',
  '@i18n-text-user-locked': '',
  '@i18n-text-user-locked-message': 'El código enviado expiró. Favor generar uno nuevo.',
  '@i18n-text-user-locked-message-manual-unlocking': 'Su usuario no pudo ser desbloqueado. Favor de comunicarse con su facilitador de cuenta.',
  '@i18n-text-user-code-expired': 'El código expiró',
  '@i18n-text-user-code-expired-message': 'Su usuario no pudo ser desbloqueado. Favor de comunicarse con su facilitador de cuenta.',
  '@i18n-text-time-expired': 'Tiempo expirado',
  '@i18n-text-time-expired-message': 'Estimado usuario, el tiempo de espera para ingresar el código enviado a su correo ha caducado, favor intentelo nuevamente',
  '@i18n-text-response-invalid': 'Respuesta inválida. ',
  '@i18n-text-response-invalid-message': 'Respuesta invalida. Intente nuevamente.',
  '@i18n-text-invalid-credentials': 'Credenciales inválidas',
  '@i18n-text-invalid-credentials-message': 'Estimado cliente, El usuario ó correo ingresado es inválido',




  '@i18n-quote-commercialReferences-section.title': 'Referencias Comerciales (distintas a proveedores)',
  '@i18n-quote-commercialReferences-commercialReferencesTable-section.title': '',
  '@i18n-quote-commercialReferences-checkCommercialReferences-section.title': '',

  '@i18n-quote-commercialReferences-form-label-mainPhone': 'Teléfono Principal',
  '@i18n-quote-commercialReferences-form-label-otherPhone': 'Teléfono Otro',
  '@i18n-quote-commercialReferences-form-label-averageDigit': 'Cifras promedio',
  '@i18n-quote-commercialReferences-modal-section-2-title': 'Referencias Comerciales (distintas a proveedores)',
  '@i18n-quote-commercialReferences-modal-section-2-subtitle': '',
  '@i18n-quote-commercialReferences-form-label-checkCommercialReferences': 'Declaro que la persona no posee referencias comerciales (corresponden a lo señalado en principales proveedores)"',

  '@i18n-error-message-legal-contact-not-found': 'Contacto no existe',
  '@i18n-quote-legalRepresentative-form-label-nacionality': 'Nacionalidad',

  '@i18n-quote-account-commercial-relations': 'Referencias Comerciales (distintas a proveedores)',

  '@i18n-no-requirements': 'No hay requisitos pendientes',

  '@i18n-requirements-digital-optional': 'Digital Opcional',
  '@i18n-requirements-phisycal-optional': 'Físico Opcional',
  '@i18n-requirements-no-documents-loaded': 'Agregue su(s) documento(s)',
  '@i18n-requirements-expired-abrv': 'Vcto.',
  '@i18n-requirements-choose-file': 'Anexar archivo',
  '@i18n-requirements-button-save': ' <i class="mr-2  fas fa-save"></i> Guardar',
  '@i18n-requirements-next': ' Siguiente',
  '@i18n-requirements-back': 'Anterior',
  '@i18n-requirements-description': 'Descripción',
  '@i18n-requirements-emission': 'Emisión',
  '@i18n-requirements-expired': 'Expiración',
  '@i18n-requirements-category-documents': 'Documentos',

  '@i18n-quote-suppliers-form-label-checkSuppliers': 'Declaro que la persona no posee Principales Proveedores',
  '@i18n-quote-suppliers-checkSuppliers-section.title': '',

  '@i18n-quote-customers-form-label-checkCustomers': 'Declaro que la persona no posee Principales Clientes',
  '@i18n-quote-customers-checkCustomers-section.title': '',
  '@i18n-confirmation-are-you-shure-send-analisys': '¿Está seguro de toda la información colocada?',

  '@i18n-%': '%',
  '@i18n-text-information-not-register': 'Por favor agregue ',

  '@i18n-response-requirements-require-comments': 'Debe colocar comentarios para cada una de la secciones seleccionadas',
  '@i18n-response-requirements-require-comments-title': 'Mensaje',



  '@i18n-error-contact-person-id': 'identificador persona',
  '@i18n-error-contact-nat-person-id': 'contacto natural persona id',
  '@i18n-error-contact-type-code': 'contacto código tipo',
  '@i18n-error-contact-legal-person-id': 'contacto legal person id',
  '@i18n-error-title-legal-contact-not-found': '',
  '@i18n-error-message-contact-null': 'contacto null',
  '@i18n-error-title-contact-null': 'Mensaje',
  '@i18n-error-message-contact-per-org-null': '',
  '@i18n-error-title-contact-per-org-null': 'Mensaje',
  '@i18n-error-message-contact-ad-type-null': '',
  '@i18n-error-title-contact-ad-type-null': 'Mensaje',
  '@i18n-error-message-contact-type-null': '',
  '@i18n-error-title-contact-type-null': 'Mensaje',
  '@i18n-error-agency-code': 'código de agencia',
  '@i18n-error-agency-id': 'identificador de agencia',
  '@i18n-error-channel-code': 'código del canal',
  '@i18n-error-channel-id': 'identificador del canal',
  '@i18n-error-message-local-business-all-null': 'localbussiness es nulo',
  '@i18n-error-title-local-business-all-null': 'Mensaje',
  '@i18n-error-message-channel-all-null': 'canal es nulo',
  '@i18n-error-title-channel-all-null': 'Mensaje',
  '@i18n-error-app-prop-key': '',
  '@i18n-error-app-endp-key': '',
  '@i18n-error-message-key-param-null': 'Mensaje',
  '@i18n-error-title-key-param-null': 'Mensaje',
  '@i18n-error-bank-code': '',
  '@i18n-error-bank-id': '',
  '@i18n-error-message-currency-empty': '',
  '@i18n-error-title-currency-empty': 'Mensaje',
  '@i18n-error-message-currency-null': '',
  '@i18n-error-title-currency-null': 'Mensaje',
  '@i18n-error-message-country-code-empty': '',
  '@i18n-error-title-country-code-empty': 'Mensaje',
  '@i18n-error-message-country-code-null': '',
  '@i18n-error-title-country-code-null': 'Mensaje',
  '@i18n-error-currency-id': '',
  '@i18n-error-message-currency-from-null': '',
  '@i18n-error-title-currency-from-null': 'Mensaje',
  '@i18n-error-message-currency-to-null': '',
  '@i18n-error-title-currency-to-null': 'Mensaje',
  '@i18n-error-message-amount-null': '',
  '@i18n-error-title-amount-null': 'Mensaje',
  '@i18n-error-message-currency-conversion-null': '',
  '@i18n-error-title-currency-conversion-null': 'Mensaje',
  '@i18n-error-message-currency-id-null': '',
  '@i18n-error-title-currency-id-null': 'Mensaje',
  '@i18n-error-message-id-state-greater-zero': '',
  '@i18n-error-title-id-state-greater-zero': 'Mensaje',
  '@i18n-error-message-id-state-null': '',
  '@i18n-error-title-id-state-null': 'Mensaje',
  '@i18n-error-message-id-city-greater-zero': '',
  '@i18n-error-title-id-city-greater-zero': 'Mensaje',
  '@i18n-error-message-id-city-null': '',
  '@i18n-error-title-id-city-null': 'Mensaje',
  '@i18n-error-message-id-place-greater-zero': '',
  '@i18n-error-title-id-place-greater-zero': 'Mensaje',
  '@i18n-error-message-id-place-null': '',
  '@i18n-error-title-id-place-null': 'Mensaje',
  '@i18n-error-state-id': '',
  '@i18n-error-city-id': '',
  '@i18n-error-place-id': '',
  '@i18n-error-message-country-code-id-state-null': '',
  '@i18n-error-title-country-code-id-state-null': 'Mensaje',
  '@i18n-error-message-country-code-id-all-null': '',
  '@i18n-error-title-country-code-id-all-null': 'Mensaje',
  '@i18n-error-menu-name': '',
  '@i18n-error-message-name-null': '',
  '@i18n-error-title-name-null': 'Mensaje',
  '@i18n-error-message-id-product-greater-zero': '',
  '@i18n-error-title-id-product-greater-zero': 'Mensaje',
  '@i18n-error-message-id-plan-greater-zero': '',
  '@i18n-error-title-id-plan-greater-zero': 'Mensaje',
  '@i18n-error-message-id-audience-greater-zero': '',
  '@i18n-error-title-id-audience-greater-zero': 'Mensaje',
  '@i18n-error-person-market-type-id': '',
  '@i18n-error-person-market-type-value': '',
  '@i18n-error-alliance-id': '',
  '@i18n-error-alliance-code': '',
  '@i18n-error-message-id-plan-null': '',
  '@i18n-error-title-id-plan-null': 'Mensaje',
  '@i18n-error-message-id-product-null': '',
  '@i18n-error-title-id-product-null': 'Mensaje',
  '@i18n-error-message-id-audience-null': '',
  '@i18n-error-title-id-audience-null': 'Mensaje',
  '@i18n-error-message-business-entity-null': '',
  '@i18n-error-title-business-entity-null': 'Mensaje',
  '@i18n-error-message-to-recipient-null': '',
  '@i18n-error-title-to-recipient-null': 'Mensaje',
  '@i18n-error-message-text-null': '',
  '@i18n-error-title-text-null': 'Mensaje',
  '@i18n-error-message-subject-null': '',
  '@i18n-error-title-subject-null': 'Mensaje',
  '@i18n-error-message-msg-attachment-null': '',
  '@i18n-error-title-msg-attachment-null': 'Mensaje',
  '@i18n-error-bracket-close-method': '',
  '@i18n-error-bracket-close-unable-email': 'correo no disponible',
  '@i18n-error-bracket-close-incorrect-email': 'correo incorrecto',
  '@i18n-error-with-username': '',
  '@i18n-error-with-tran-number': '',
  '@i18n-error-with-subject': '',
  '@i18n-error-and-source': '',
  '@i18n-error-message-formsheet-member-variable': '',
  '@i18n-error-message-formsheet-is-not-of-type': '',
  '@i18n-error-message-formsheet-keylabel-of-object-is': '',
  '@i18n-error-message-formsheet-the-parameter': '',
  '@i18n-error-message-formsheet-the-class-indicated-by-the-parameter': '',
  '@i18n-error-message-formsheet-the-class-enum': '',
  '@i18n-error-message-formsheet-does-not-have-defined-constants': '',
  '@i18n-error-message-formsheet-could-not-be-divided-split': '',
  '@i18n-error-message-policies-not-found': '',
  '@i18n-error-title-policies-not-found': 'Mensaje',
  '@i18n-error-title-req-or-sections-incompleted': 'Mensaje',
  '@i18n-error-message-req-or-sections-incompleted': 'Debe agregar las secciones incompletas ó rechazadas',
  '@i18n-error-is-different': '',
  '@i18n-error-not-allowed': '',
  '@i18n-error-message-is-not-a-adminuser': 'no es un usuario administrador',
  '@i18n-error-message-date-cannot-be-greater-than-current-date': 'la fecha no puede ser superior a la fecha actual',
  '@i18n-error-title-date-cannot-be-greater-than-current-date': 'Mensaje',
  '@i18n-error-title-transaction-can-not-be-deleted': 'Mensaje',
  '@i18n-error-message-the-user-is-not-the-requester': '',
  '@i18n-error-message-is-an-active-transaction-of-another-version': 'la transacción se encuentra activa en otra versión',
  '@i18n-error-message-anp-null': '',
  '@i18n-error-title-anp-null': 'Mensaje',
  '@i18n-error-acc-np-id': '',
  '@i18n-error-message-pn-address-null': '',
  '@i18n-error-title-pn-address-null': 'Mensaje',
  '@i18n-error-message-pn-cp-null': '',
  '@i18n-error-title-pn-cp-null': 'Mensaje',
  '@i18n-error-message-max-upload-file-reached': 'Tamaño del archivo excede el máximo permitido',

  '@i18n-error-message-getting-currencies': 'Error obteniendo las monedas',
  '@i18n-error-message-queue-not-found-with-parameters': 'Cola no encontrada con los siguientes parametros:',
  '@i18n-error-message-workspace-not-found-with-parameters': 'Workspace no encontrado con los siguientes parametros:',
  '@i18n-error-message-code-queue-null': 'El código de la cola es requerido',
  '@i18n-error-title-code-queue-null': 'Mensaje',
  '@i18n-error-message-account-editaken': 'No se puede liberar el trámite porque está marcado en modo edición',
  '@i18n-error-title-account-editaken': 'Mensaje',


  '@i18n-requirements-max-size-accepted': 'Peso Máx.',
  '@i18n-requirements-formats-accepted': 'Formatos',




  '@i18n-requirement-type-folder': 'Carpeta',
  '@i18n-requirement-type-file': 'Archivo',

  '@i18n-requirements-creation': 'Emisión ',
  '@i18n-requirements-expiration': 'Vencimiento',

  '@i18n-section-title-mobilization-information': 'Información sobre la movilización',

  '@i18n-quote-productInformation-form-label-planName': 'Plan',

  '@i18n-error-acc-plan-person-type': 'El plan seleccionado no corresponde con el tipo de persona que inició sesión',
  '@i18n-error-server-internal-alert': 'Mensaje',
  '@i18n-popup-text-alert-confirmation-token-invalid-type-person-1': 'Disculpe, el producto solicitado no esta disponible para',
  '@i18n-popup-text-alert-confirmation-token-invalid-type-person-2': 'Si usted ya es cliente ',
  '@i18n-popup-text-alert-confirmation-token-invalid-type-person-3': ' puede ingresar con su cuenta y realizar la solicitud.',
  '@i18n-popup-text-alert-confirmation-token-invalid-type-person-4': 'Si aún no es cliente ',
  '@i18n-popup-text-alert-confirmation-token-invalid-type-person-5': ' cree una cuenta nueva.',
  '@i18n-popup-text-alert-confirmation-token-invalid-type-person-6': 'Si tiene una cuenta ',
  '@i18n-popup-text-alert-confirmation-token-invalid-type-person-7': ' ingrese con esta. ',


  '@i18n-popup-text-continue': 'Mantener en sesión',
  '@i18n-popup-text-logout': 'Salir',
  '@i18n-popup-text-ok': 'Ok',



  '@i18n-error-message-code-wkspace-null': 'Advertencia',
  '@i18n-error-title-code-wkspace-null': 'El código del workspace no puede ser nulo',
  '@i18n-error-message-workspace-not-found-with-id': 'EL espacio de trabajo con identificador',
  '@i18n-error-message-workspace-is-active-for-user': 'se encuentra activo para el usuario',
  '@i18n-error-message-user-profile-does-match-view-workspace': 'El perfil del usuario no corresponde con el tipo de vista del espacio de trabajo',
  '@i18n-error-title-user-profile-does-match-view-workspace': 'Error al asignar el workspace',
  '@i18n-error-message-workspace-is-not-for-user': 'no se encuentra activo para ese usuario',
  '@i18n-error-message-user-null': 'Se debe indicar el usuario que ejecuta esta asignación',
  '@i18n-error-title-user-null': 'falta información',
  '@i18n-error-message-acc-cant-taken-state': 'El trámite no puede ser tomado en modo edición porque no se encuentra en estado en proceso o incompleto',
  '@i18n-error-title-acc-cant-taken-state': 'Error al Tomar un trámite',
  '@i18n-error-message-acc-cant-taken-role': 'El trámite está siendo editado por el ejecutivo',
  '@i18n-error-title-acc-cant-taken-role': 'Error al Tomar un trámite',

  '@i18n-requirements-progress-attach': 'Adjuntos',
  '@i18n-requirements-progress-approved': 'Aprobados',
  '@i18n-requirements-progress-incompleted': 'Incompletos',
  '@i18n-requirements-progress-rejected': 'Rechazados',
  '@i18n-requirements-progress-inconsistent': 'En revisión',
  '@i18n-no-requirements-criteria': 'Para el criterio: ',
  '@i18n-requirements-uploaded': 'Subido',
  '@i18n-requirements-response': 'Respuesta: ',
  '@i18n-requirements-version': 'Versión ',
  '@i18n-requirements-old-responses': 'Respuestas:',
  '@i18n-requirements-text-msg-incompleted': 'Cuando responda debe agregar todos los archivos de nuevo.',

  '@i18n-text-considerations': 'Consideraciones',
  '@i18n-text-considerations-general': 'Consideraciones Generales',


  '@i18n-quote-testRadio-section.title': "Test de Botones tipo select",

  '@i18n-quote-attribute-radio': '¿ Tipo de persona relacionada ?',
  '@i18n-quote-option-list-radio-option-1': "Representante Legal",
  '@i18n-quote-option-list-radio-option-2': "Autorizado",
  '@i18n-quote-option-list-radio-option-3': "Firmante",
  '@i18n-quote-option-list-radio-option-4': "Cuentadante",
  '@i18n-quote-option-list-radio-option-5': "Otro",


  '@i18n-quote-attribute-check': '¿ Cuales son sus colores preferidos ?',
  '@i18n-quote-option-list-check-option-1': 'Azul',
  '@i18n-quote-option-list-check-option-2': "Amarillo",
  '@i18n-quote-option-list-check-option-3': "Verde",
  '@i18n-quote-option-list-check-option-4': "Rojo",
  '@i18n-quote-option-list-check-option-5': 'Morado',
  '@i18n-quote-option-list-check-option-6': "Otro",


  '@i18n-quote-fiscalAddress-form-label-addressType': 'Tipo de Dirección',
  '@i18n-quote-fiscalAddress-form-label-phone-number': 'Número de Teléfono',
  '@i18n-quote-fiscal-directions-section.title': 'Direcciones',
  '@i18n-quote-fiscal-directions-section.title-plural': 'Direcciones',
  'FISCAL_ADDRESS_PRINCIPAL': 'Principal',
  'FISCAL_ADDRESS_OFICINA': 'Oficina',
  '@i18n-form-pattern-date-name': 'Solo se aceptan letras.',




  '@i18n-quote-pn-form-label-country-of-birth': 'País',
  '@i18n-section-title-PERSONAL_INFORMATION_BIRTH_DATA': 'Información de Nacimiento',

  '@i18n-workflow-name-interview': 'Entrevista',
  '@i18n-workflow-name-requirement': 'Recaudos',

  '@i18n-text-register-account-opening': 'Completado',
  '@i18n-workflow-name-requirements': 'Adjuntado',
  '@i18n-text-register-account-opening-2': 'Completar',
  '@i18n-workflow-name-requirements-2': 'Adjuntar',
  '@i18n-text-register-interview': '',


  '@i18n-quote-ownBusiness-suppliers-form-label-checkSuppliers': 'Declaro que no poseo principales proveedores',
  '@i18n-quote-ownBusiness-customers-form-label-checkCustomers': 'Declaro que no poseo principales clientes',

  '@i18n-quote-bankAccount-form-label-accountNumber': 'Número de Producto',
  '@i18n-product-link-plan': 'Ver',

  '@i18n-register-text-standard': 'Agregue su(s) ',
  '@i18n-register-text-standard-paperwork': 'No se encontraron solcitudes ',

  '@i18n-register-text-standard-table': 'Lista de ',
  '@i18n-sworn-text-tips':
    `
<div class="mb-3 font-weight-bold">
Tips
</div>
<div  class="mt-4 text-secondary-2">
* Su solicitud será recibida por nuestra unidad de análisis y será revisada en los términos correspondientes en los acuerdos de servicio.
</div><div  class="mt-4 text-secondary-2"> 
* Las solicitudes se reciben y analizan por orden de llegada. 
</div><div  class="mt-4 text-secondary-2">
* Cuando su solicitud haya sido analizada por el banco, le notificaremos por email y usted podrá ingresar a ella para consultarla.
</div>
  `,
  '@i18n-title-header-menu-left': 'TRÁMITES',

  '@i18n-text-only-can-register-one-principal-direction': 'Solo se puede agregar una Dirección Principal',

  '@i18n-account-interview-step-name': '&nbsp;Entrevista',
  '@i18n-quote-shareholders-related-shareholdersTable-section.title': 'Accionistas Relacionados',
  '@i18n-text-msj-exceed-main-shareholders': 'No se pueden agregar más de ',
  '@i18n-text-msj-max': ' Accionistas Principales.',
  '@i18n-error-shareholders-max-count-msj': 'Mensaje!',
  '@i18n-error-shareholders-max-count': ' No se pueden agregar más accionistas.',
  '@i18n-form-button-sectionSave-2': 'Autoguardado en ',
  '@i18n-store-front-login-form-confirmPassword': 'Confirmación de contraseña',
  '@i18n-error-title-shareholders-max-count': 'Mensaje!',


  '@i18n-text-register': 'Hazte cliente',
  '@i18n-text-login': 'Login',
  '@i18n-text-label-agency': 'Agencia ',
  '@collections-text-error-date-expedition-required': 'La fecha de Emisión es requerida.',
  '@collections-text-error-date-expiration-required': 'La fecha de Expiración es requerida.',

  '@i18n-stage-client-interview': '',
  '@i18n-section-fiscal-directions-table-column-direction': 'Dirección',
  '@i18n-text-sworn-requirements': 'Estimado(a) cliente, antes de enviar su solicitud, es necesario cargar algunos recaudos en digital.',

  '@i18n-text-nationality': 'Nacionalidad',
  '@i18n-person-type': 'Tipo de Documento',
  '@i18n-text-identity': 'Número de Documento',

  '@i18n-ak-tep-next': '<span class="ak-font-roboto"> Siguiente <i class="pl-2 fas fa-arrow-right"></i></span>',
  '@i18n-form-button-sectionSave-continue': '<span class="ak-font-roboto"> Empieza ahora <i class="pl-2 fas fa-arrow-right"></i></span>',
  '@i18n-form-button-sectionSave-continue-verif':'Ok',


  '@i18n-text-January': 'Enero',
  '@i18n-text-February': 'Febrero',
  '@i18n-text-March': 'Marzo',
  '@i18n-text-April': 'Abril',
  '@i18n-text-May': 'Mayo',
  '@i18n-text-June': 'Junio',
  '@i18n-text-July': 'Julio',
  '@i18n-text-August': 'Augosto',
  '@i18n-text-September': 'Septiembre',
  '@i18n-text-October': 'Octubre',
  '@i18n-text-November': 'Noviembre',
  '@i18n-text-December': 'Diciembre',

  '@i18n-text-Jan': 'Ene',
  '@i18n-text-Feb': 'Feb',
  '@i18n-text-Mar': 'Mar',
  '@i18n-text-Apr': 'Abr',
  '@i18n-text-Jun': 'Jun',
  '@i18n-text-Jul': 'Jul',
  '@i18n-text-Aug': 'Ago',
  '@i18n-text-Sep': 'Sep',
  '@i18n-text-Oct': 'Oct',
  '@i18n-text-Nov': 'Nov',
  '@i18n-text-Dec': 'Dic',
  '@i18n-new-product': ' <i class="pr-1 ak-text-color-4   fas fa-credit-card "></i> Nueva Solicitud',
  '@i18n-text-error-bussiness-not-exist': 'Ya la empresa está registrada.',

  '@i18n-hello': 'Hola ',



  
  '@i18n-menu-label-client-inbox': '<i class="fas fa-envelope-open-text"></i> Con respuesta',
  '@i18n-menu-label-client-sent': '<i class="fas fa-paper-plane"></i> Espera de análisis',
  '@i18n-menu-label-client-draft': '<i class="fas fa-pencil-alt"></i> Borrador',

  '@i18n-menu-label-client-inbox-icon': '<i class="fas fa-envelope-open-text"></i>',
  '@i18n-menu-label-client-sent-icon': '<i class="fas fa-paper-plane"></i>',
  '@i18n-menu-label-client-draft-icon': '<i class="fas fa-pencil-alt"></i>',

  '@i18n-currency-label2': 'Seleccione la <b>moneda</b> para su cuenta',
  '@i18n-agency-label2': '',
  '@i18n-quote-productInformation-form-label-averageMonthlyAmount': 'Monto promedio mensual',

  
  "--------------------------030521": "",

  "@i18n-quote-option-list-accountType-currencyCustody": "Custodia de Divisas",

  "@i18n-description-bottom-isPep": "",

  "@i18n-description-top-relatedToPEP": ``,



  '@i18n-quote-RELATED-PEP-PN-ASSOCIATION-modal-section-title': "PEP Relacionados",
  "@i18n-quote-RELATED-PEP-PN-modal-section-title": "PEP Relacionados",




  '@i18n-quote-productPlanSelector-form-label-product': ` ¿Cuál <b>producto</b> desea usted solicitar? `,
  '@i18n-quote-bankAccouproductPlanSelectornt-planaccountType': `¿Qué <b>tipo</b> de cuenta desea usted solicitar?  `,

  '@i18n-quote-productInformation-form-label-currency': 'Seleccione la <b>moneda</b> para su cuenta',

  '@i18n-text-plan-recaudos-subtitle': 'Tenga a la mano los siguientes recaudos para consignarlos digitalmente. Una vez aprobada la solicitud podrá consignar en físico los documentos en nuestras agencias.',
  '@i18n-form-pattern-number': 'Solo números',
  '@i18n-quote-pn-form-label-civilStatus': 'Seleccione su <b>Estado Civil</b>',
  '@i18n-quote-pn-form-label-gender': 'Seleccione su <b>Género</b>',

  '@i18n-error-message-enterprise-not-created-core': 'Empresa no creada en el Core',
  '@i18n-error-message-telephone-null': 'Indique por favor Número de Teléfono.<br>Diríjase a la sección de Dirección del Negocio a través de la opción Editar para completar el siguiente dato.',
  '@i18n-error-title-telephone-null': '',
  '@i18n-error-title-email-null': '',
  '@i18n-error-message-shareholders-null': 'Indique por favor los accionistas',
  '@i18n-error-title-shareholders-null': '',
  '@i18n-error-message-source-of-founds-null': 'Indique por favor el destino de los fondos',
  '@i18n-error-title-source-of-founds-null': '',
  '@i18n-error-message-request-reason-null': 'Indique por favor la razón de la petición',
  '@i18n-error-title-request-reason-null': '',
  '@i18n-error-message-w8-null': 'Adjunte por favor Formulario W8.<br>Diríjase al módulo de Recaudos a través de la opción Editar para anexar el siguiente documento.',
  '@i18n-error-title-w8-null': '',
  '@i18n-error-message-w9-null': 'Adjunte por favor Formulario W9.<br>Diríjase al módulo de Recaudos a través de la opción Editar para anexar el siguiente documento.',
  '@i18n-error-title-w9-null': '',
  '@i18n-error-message-interview-null': 'Cargue por favor la Entrevista.<br>Diríjase al módulo de Entrevista a través de la opción Editar para registrarla.',
  '@i18n-error-title-interview-null': '',
  '@i18n-error-title-user-password-expired': '',
  '@i18n-error-message-user-password-expired': 'Contraseña expirada.<br>¿Desea actualizar su contraseña ahora?<br>',
  '@i18n-text-shared-service-center': 'Canal de servicio',

  "@i18n-quote-option-list-accountType-foreign-exchange": 'Custodia de Divisas',
  '@i18n-quote-option-list-accountType-tdc': 'Tarjeta de Crédito',
  '@i18n-quote-termsAndConditions': '',
  "@i18n-new-solicitud": `       

        <div  class="mb-3"> 1. Te asignaremos, de manera inmediata, un Ejecutivo de Negocio que te podrá apoyar
            </div>
    
          <div  class="mb-3" >2. Completa y envía la solicitud con sus recaudos
            </div>
    <div  class="mb-3">3. Una vez recibida nuestros analistas y te darán respuesta en un período no mayor a 24 hrs
    </div> `,



  '@i18n-form-pattern-web-name': 'Sitio Web, Blog, y/o Redes Sociales Inválido',
  '@i18n-error-message-email-null': 'El correo es Inválido',
  '@i18n-compleat-section': '<i class="fas fa-pencil-alt"></i>',
  '@i18n-incompleat-section': 'Completar',
  "@i18n-description-top-relatedToPepAssociation": ``,
  '@i18n-description-top-isPep': '',
  '@i18n-quote-anotherSourceIncome-form-label-help': '',


  '@i18n-requirements-download-all-filez-zip': ' Descargar todos',


  'clientName': '{clientName}',
  "@i18n-step-disabled": "Complete el paso anterior",

  '@i18n-rq-box-msg': "Para mayor rapidez del proceso de aprobación de tu solicitud, te sugerimos consignar la mayor cantidad de recaudos posibles digitalmente. Aquellos recaudos que no adjuntes digitalmente, podrás consignarlos personalmente.",

  '@i18n-text-information-the-legal-representative-dont-be-deleted': 'El representante legal no puede ser eliminado',

  '@i18n-quote-interview-form-label-interviewDate': 'Fecha de la Entrevista',
  '@i18n-quote-interview-form-label-interviewType': '¿Tipo de Entrevista?',
  '@i18n-quote-option-list-interviewType-option1': 'Personal',
  '@i18n-quote-option-list-interviewType-option2': 'Teléfonica',
  '@i18n-quote-option-list-interviewType-option3': 'Video Llamada',
  '@i18n-quote-interview-form-label-interviewDescription': 'Descripción de la Entrevista',
  '@i18n-error-message-cannot-repeat-existing-password': 'La contraseña debe ser distinta a la última utilizada. Por favor ingrese una nueva contraseña para continuar.',
  '@i18n-error-title-cannot-repeat-existing-password': '',


  '@i18n-text-error-requirements-files-need-dates-information': 'Antes de enviar su solicitud a análisis, es necesario que al siguiente recaudo se le indique las fechas requeridas en todos sus archivos: ',
  '@i18n-workflow-name-interview-ant': ' la ',
  '@i18n-workflow-name-accountopening-ant': ' los ',
  '@i18n-workflow-name-requirement-ant': ' los ',
  '@i18n-error-msg-legalrep-null': 'Representante Legal no encontrado',
  '@i18n-error-title-legalrep-null': '',
  '@i18n-text-button-send-request': 'Aceptar y enviar solicitud',

  '@i18n-error-pattern-no-special-characters-or-number' :'No se permiten caracteres especiales ó numeros',
  '@i18n-error-pattern-no-special-characters' :'No se permiten caracteres especiales',

  

  '@i18n-form-button-sectionSave-compleat':`
  <span class="float-right">
  <i class="text-primary fas fa-cloud pl-2 "></i> 
  <small>Almacenado</small>
  </span>
  `,

  '@i18n-guide-product-information-name': 'Datos del producto',
  '@i18n-guide-client-identification-name': 'Tus datos completados',
  '@i18n-guide-requirement-title': 'Tus recaudos completados',

  '@i18n-guide-account-contract-name': 'Solicitud enviada',
  '@i18n-guide-account-contract-analysis-name': 'Solicitud revisada',
  '@i18n-guide-account-activation-name': 'Producto entregado',

  '@i18n-guide-account-activation-description': 'Bienvenida y retiro del producto',
  '@i18n-guide-product-information-description': 'Datos y parámetros del producto',
  '@i18n-guide-client-identification-description': 'La información del cliente',
  '@i18n-guide-account-contract-analysis-description': 'Revisión por nuestros analistas',
  '@i18n-guide-account-contract-description': 'Enviar solicitud para el análisis',
  '@i18n-guide-requirement-description': 'Registrar los requisitos	',

 
  '@i18n-guide-interview-description': 'Entrevista	',
  '@i18n-guide-interview-title': 'Video llamada	',


  "@i18n-error-title-w8-not-verified": '',
  "@i18n-error-title-w9-not-verified": '',
  "@i18n-error-message-w8-not-verified": 'Debe responder el formulario W8 como aprobado',
  "@i18n-error-message-w9-not-verified": 'Debe responder el formulario W9 como aprobado',
  "@i18n-error-title-requirements-has-no-issue-date": "El recaudo no tiene fecha de emisión",

  "@i18n-error-message-requirements-no-upload-file_document-manager":'No se pudo subir el archivo al gestor documental. Intentelo Nuevamente',
  "@i18n-error-title-requirements-no-upload-file_document-manager":'Upload de archivo',

  
  "@i18n-error-title-requirements-has-no-emission-date": "",
  "@i18n-error-message-requirements-has-no-emission-date": "Diríjase al módulo de Recaudos a través de la opción Editar para ingresar las fechas de vigencias (Emisión / Expiración)",

'@i18n-quote-interIdentityVerifIntro-section.title':'',

  '@i18n-quote-interIdentityVerifInfo-section.title':`
  <div class="text-left pt-4">

  <div>
      <span class="pt-2 pb-2 font-weight-light">Verifícate</span>
      <div class="line">
          <div class="line2 font-weight-light"></div>
      </div>
  </div>
  <div class="mt-4 mt-5 ">

      <div class="font-weight-bold ">Información de Identidad</div>
      <div class="font-weight-light mt-4 small">Estos datos deben coincidir con su información legal tal y como aparece en su documento de identificación.  </div>

  </div>

</div>


      `,
      '@i18n-quote-pn-form-label-birthDate-identity-verif': 'Fecha de Nacimiento' ,

      '@i18n-quote-pn-form-label-typeIdentity-identity-verif': 'Tipo de Identificación',

  '@i18n-quote-pn-form-label-nationality-identity-verif': 'Nacionalidad',

  '@i18n-quote-pn-form-label-identity-identity-verif': 'Número de Documento',

  '@i18n-error-client-not-complete-identification': 'Para enviar el trámite, debe completar la Verificación de Identidad',

  'PRODUCT_INFORMATION': 'Producto Solicitado',
  'ACCOUNT_CONTRACT_DECLARATION': 'Cierre de la Solicitud',
  'PRODUCT_INFORMATION_ACC_L1': 'Producto Solicitado',
  'ACCOUNT_CONTRACT_ACC_L1': 'Cierre de la Solicitud'
  ,'@i18n-quote-interIdentityVerifPhotos-section.title':'',
  '@i18n-workflow-name-clientdata':'Información de Cliente',

  '@i18n-quote-interIdentityVerifEnd-section.title':'',

  '@i18n-termAndConditions':' Al enviar doy fe que toda la información es fidedigna y autorizo al banco a validar su veracidad.',
  '@i18n-msg-cuentas':'En caso de no agregar un registro en este apartado y continuar con la gestión de su cuenta, se tomará como que no posee Referencias Bancarias con otras Instituciones, completándose la sección. De todas maneras la sección estará disponible para agregarlos registros necesarios en caso de poseerlas.',
  '@i18n-declaracionJurada-contrato':``,
  '@i18n-formatoSol':`Cuando su solicitud este lista descargue la Ficha Única, el Contrato y Declaración Jurada. Debe firmarlos y anexarlos en el recaudo de Formatos de la Solicitud
  `,
  
  '@i18n-product-long-description-product1-2':`
  
    <div  class="fz-14"> 
    Deseo movilizar  hasta Bs. 1.000,00 mensuales
    </div>


  `,
  '@i18n-product-long-description-product1-3':`

      <div class="fz-14">
      Deseo movilizar
      más de Bs. 1.000,00 mensuales
    </div> 
    <div class="mt-1">
      <span class="recomend badge badge-secondary pt-1 pb-1">Recomendado</span>
     </div>

`,
'@i18n-modal-transacton-product-title':'Cuenta Bancaria',
'@i18n-modal-transacton-plan-title':'Selecciona el tipo de cuenta',

'@i18n-quote-pn-form-label-taxId':'Registro de Identificación Fiscal (RIF) Personal',
'@i18n-workflow-name-identityverification':'',


'@i18n-quote-pn-form-label-startDate':'Fecha de Inicio',
'@i18n-quote-pn-form-label-dueDate':'Fecha de culminación',

'@i18n-quote-spouse-form-label-occupation':'Profesión u Oficio',
"@i18n-related-persons-asociados-section.sub-title": 'PEP Asociados',



"-------- PE ----":"",


 
'@i18n-htmlRightTemplete-BUSINESS_DATA':`
<div class="p-1 pl-2  mt-2 mb-2 ">
    <div >
        <div class="base-description-section-top mb-2 font-weight-bold">
        Datos Básicos
        </div>

        <div class="text-form-description ">
        Se refiere a la información de la empresa, tales como: Razón Social, Forma Jurídica,  Número de RIF, entre otros.<br><br>
        Esta información estará visible a su ejecutivo asignado. De este modo, le resultará más fácil validar la información y ponerse en contacto con los representantes de la empresa.        </div>
     </div>
</div>
`,

'@i18n-htmlRightTemplete-CONTACT_MEANS':`
<div class="p-1 pl-2  mt-2 mb-2 ">
    <div >
        <div class="base-description-section-top mb-2 font-weight-bold">
         Medios de Contacto
        </div>

        <div class="text-form-description ">
        Indique todos los medios de contactos disponibles por medio de los cuales podemos contactar con la empresa.
        
        </div>
     </div>
</div>
`,

'@i18n-htmlRightTemplete-CONSTITUTION_DATA':`
<div class="p-1 pl-2  mt-2 mb-2 ">
    <div >
        <div class="base-description-section-top mb-2 font-weight-bold">
        Datos de Constitución
        </div>

        <div class="text-form-description ">
        Por favor indique los datos de registro de la empresa. En caso de poseer actualizaciones bien sea por nombramiento o vencimiento de la Junta Directiva; Venta o traspaso de acciones; Aumento de Capital Social u otro motivo por favor indique los datos de registro de la última modificación.
        
        </div>
     </div>
</div>
`,



'@i18n-htmlRightTemplete-ECONOMIC_ACTIVITY':`
<div class="p-1 pl-2  mt-2 mb-2 ">
    <div >
        <div class="base-description-section-top mb-2 font-weight-bold">
        Actividad Económica
        </div>

        <div class="text-form-description ">
        Por favor indique y describa la actividad generadora de ingresos de la empresa.
        
        </div>
     </div>
</div>
`

,
'@i18n-legal-wf-title':'Cuenta Bancaria',



'@i18n-dashboard-title':`           <h4 class="text-dark font-weight-bold mt-5">
Simplifica tu día.
Resguarda el valor de tu dinero</h4>

<h6 class="text-secondary">

<div class="mt-2"> Tu tienes un negocio que operar. Nosotros tenemos una institución simplificada para ayudarte a que te mantengas enfocado
</div>


</h6>`,


'@i18n-new-product-body':`
<div class="mt-3 mb-2 font-weight-bold text-dark">Obtén tu Producto Bancario
                                </div>
                                <div class="text-secondary fz-12 text-center">
                                    <div>
                                        Cuenta Corriente, Cuenta de Ahorro, Cuenta Divisa, entre otros.
                                    </div>
                                  
                                </div>
                                `,

'@i18n-new-product-box':`<div> Solicita productos<br> y servicios <br></div>`,
                                
'@i18n-title-dashboard-transaction':`Completa tu solicitud`,


'@i18n-body-dashboard-transaction':`
   
   <div class="circle-bg bg-2 ml-auto mr-auto text-center">
   
   <i class="fas fa-chart-line  "></i>
   </div>
   
   <div class="mt-3 font-weight-bold text-dark">Perfil Transaccional</div>
   
   
   
   <div class="mt-1  fz-13">
   Origen, destino y movilización de los fondos
   </div>
   `,


'@i18n-quote-relationOfLaborDependency-section.title': 'Dependencia Laboral',
'@i18n-quote-relationOfLaborDependency-detail-section.title': 'Detalle Dependencia Laboral',
'@i18n-quote-ownBusiness-section.title': 'Negocio Propio',
'@i18n-quote-ownBusiness-section-detail.title': 'Detalle Negocio Propio',

'@i18n-workflow-name-clientdatareduced' :'Información Personal',
'@i18n-htmlSubTitle-PERSONAL_INFO_REDUCED' : 'Permítanos conocerle y contactarle más fácilmente. Su información personal estará protegida.',
'clientNameShort':'{clientNameShort}',

'@i18n-quote-businessData-form-label-businessName-identity-organization-verif':'Nombre de la Razón Social',
'@i18n-quote-businessData-form-label-taxId-organization-verif':'Número de Identificación Fiscal',
'@i18n-quote-interOrganizationVerifInfo-section.title':'Identificación de la Empresa',

'@i18n-quote-commercialReferences-form-label-businessRelationshipStartDate':'Fecha de Inicio de Relacion Comercial',
'@i18n-quote-option-list-requestReason-option13':"Acciones",

"@i18n-product-long-description-product1-4":'Fondo Mutual',

'@i18n-quote-createUser-form-label-secondLastName': 'Segundo apellido',
'@i18n-quote-createUser-form-label-secondName': 'Segundo nombre',
'@i18n-text-user-registred-detail2': 'Iniciar sesión en {clientName}, para  continuar el proceso de pre-apertura de cuenta.',
'@i18n-quote-relationOfLaborDependency-form-label-other-phone': 'Teléfono',
'@i18n-quote-relationOfLaborDependency-address-section.title': 'Dirección Laboral',
'@i18n-faceapi-title':`           <h5 class="text-dark font-weight-bold mt-5">
Acme Bank</h5>

<h6 class="text-secondary">
Necesita validar tu fé de vida
</h6>`,
'@i18n-quote-own_business-address-section.title': 'Dirección de negocio propio',
'@i18n-quote-own_business-registry-data-section.title': 'Datos de registro del negocio propio',
'@i18n-quote-ownbusiness-suppliers-pn-section.title': 'Provedores de Negocio Propio',
'@i18n-quote-ownbusiness-customers-pn-section.title': 'Clientes de negocio propio',
'@i18n-quote-option-list-typeOfCompany-option1': 'Personas Jurídicas',
'@i18n-quote-option-list-typeOfCompany-option2': 'Asociaciones Cooperativas',
'@i18n-quote-option-list-typeOfCompany-option3' : 'Fideicomisos',
'@i18n-quote-option-list-typeOfCompany-option4':'Organizaciones sin fines de lucro',
'@i18n-quote-businessData-form-label-typeOfCompany': 'Tipo de Empresa',

'@i18n-quote-ownbusiness-address-section.title': 'Dirección de Negocio Propio',
'@i18n-text-facial-recognition-disabled': 'La fé de vida fue desáctivada, ahora puede subir su identificación.',

'@i18n-quote-ISRLDeclaration-form-label-year': 'Ultimo Año',
'@i18n-quote-ISRLDeclaration-form-label-year1': 'Penúltimo Año',
'@i18n-quote-ISRLDeclaration-form-label-amount1': 'Monto Neto Pagado',
'@i18n-quote-ISRLDeclaration-form-label-year2': 'Antepenúltimo Año',
'@i18n-quote-ISRLDeclaration-form-label-amount2': 'Monto Neto Pagado',

'@i18n-quote-option-list-sourceOfFunds-option17': 'PROPIEDADES',
'@i18n-quote-option-list-sourceOfFunds-option18': 'INVERSIONES',
'@i18n-quote-option-list-sourceOfFunds-option19': 'ACTIVIDADES RELIGIOSAS',
'@i18n-quote-option-list-sourceOfFunds-option20': 'DIVIDENDOS',
'@i18n-quote-option-list-sourceOfFunds-option21': 'HERENCIA',
'@i18n-quote-option-list-sourceOfFunds-option22': 'VENTA DE JOYAS',
'@i18n-quote-option-list-sourceOfFunds-option23': 'VENTA DE BIENES MUEBLES',
'@i18n-quote-option-list-sourceOfFunds-option24': 'DONACIÓN',
'@i18n-quote-option-list-sourceOfFunds-option25': 'VENTA DE BIENES INMUEBLES',
'@i18n-transaction-send-success': `<b>Felicitaciones</b> , tu solicitud esta lista.<br><br> Por favor procede a enviarla para que nuestros analistas la revisen.<br> <br> Te responderemos en un tiempo no mayor a 59 min una vez recibida.<br><br> Si toda
la información está correcta tu producto o servicio bancario será creado en línea al momento de la aprobación. Recibirás las instrucciones y próximos pasos por correo.`,

'@i18n-quote-investorProfile-form-label-profile': 


`
<div class="text-left mb-2">
  <div class="font-weight-bold  mb-1" >Perfil</div>
  <div class="text-secondary font-weight-normal ">
  Seleccione la opción de inversión que mejor describe su perfil de inversionista
  </div>
</div>
` ,


'@i18n-quote-option-list-investorProfile-option1':
  
  `
  <div class="text-left">
    <div class=" fz-12 font-weight-bold  mb-1" >AGRESIVO</div>
    <div class="fz-12">
      Tengo alta tolerancia a la pérdida de capital, por lo que me gustaría que mis inversiones generaran los mayores rendimientos posibles. Estoy dispuesto a aceptar fluctuaciones negativas por períodos mayores a dos años, incluyendo la posible pérdida de mi inversión inicial.
    </div>

  `,
'@i18n-quote-option-list-investorProfile-option2':


`
<div class="text-left">
  <div class="fz-12 font-weight-bold  mb-1" >MODERADO</div>
  <div class="fz-12">
  Puedo aceptar fluctuaciones negativas, incluyendo posibles pérdidas en mi inversión inicial en el mediano plazo, con el fin de obtener tasas considerablemente mayores a las tasas activas vigentes por el BCV.
  </div>
</div>
` ,

'@i18n-quote-option-list-investorProfile-option3':

`
<div class="text-left">
  <div class="fz-12 font-weight-bold  mb-1" >CONSERVADOR</div>
  <div class="fz-12">
    Me gustaría preservar mi capital invertido, pero estoy dispuesto a aceptar pequeñas fluctuaciones en el valor del mismo, incluyendo posibles pérdidas en mi inversión inicial.   </div>
</div>
`,
'@i18n-quote-investor-profile-section-title': 'Perfil de inversión',
'@i18n-investor-profile-step-name': 'Perfil de Inversión',

'@i18n-quote-option-list-typeOfCompany-option5':'Caja de Ahorro',
'@i18n-quote-option-list-typeOfCompany-option6':'Empresas en Formación',
'@i18n-quote-option-list-typeOfCompany-option7':'Consejos Educativos',
'@i18n-quote-option-list-typeOfCompany-option8':'Condominios',
'@i18n-quote-option-list-typeOfCompany-option9':'Sindicatos',
'@i18n-quote-option-list-typeOfCompany-option10':'Sociedades Extranjeras',
'@i18n-quote-option-list-typeOfCompany-option11':'Entes Gubernamentales',

'@i18n-error-have-active-request': `<div  class="ml-3 mr-3 text-center animate__animated animate__fadeIn animate__faster alert alert-warning text-dark p-3 animate__animated animate__fadeIn fz-14">
<b> Usted ya posee una solicitud activa
</b>
<div class="text-secondary">Solo puede gestionar una solicitud a la vez. <br> Si desea gestionar otra solicitud, debe concluir/enviar o borrar la que tiene en proceso

</div>
</div>
`,

'@i18n-quote-boardDirectors-form-label-startDate':'Fecha de inicio en junta directiva',
'@i18n-quote-boardDirectors-form-label-duetDate':'Fecha de culminacion en junta directiva',

'@i18n-error-title-duplicate-fiscal-address':'Mensaje',
'@i18n-error-message-duplicate-fiscal-address':'Ya la dirección fiscal se encuentra registrada',
'@i18n-quote-marketInformation-form-label-totalMonthlySales': ' Ventas mensuales',

'@i18n-error-insufficient-income': 'Ingresos Insuficientes',
'@i18n-error-title-insufficient-income': 'Mensaje',
'@i18n-error-unregistered-income': 'Ingresos no registrados',
'@i18n-error-title-unregistered-income': 'Mensaje',





};

