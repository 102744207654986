<div *ngIf="dinamicCssUrl">
    <!-- Estilos Dinámicos-->
    <link rel="stylesheet" [href]="dinamicCssUrl+'/style.css' | safe">
    <link rel="stylesheet" [href]="dinamicCssUrl+'/ak-styles-config.css' | safe">
    <link rel="stylesheet" [href]="dinamicCssUrl+'/ak-library-styles-config.css' | safe">

</div>
<input type="file" accept="image/*" capture="camera">


<div *ngIf="loadingAutoSave || loadingAutoSaveOut" class="ak-alert toast botton-right  {{loadingAutoSave?'animate__animated animate__fadeIn animate__faster ':'' }}  {{loadingAutoSaveOut?'d-none ':'' }} ">

    <div class="toast-body">
        Guardando
        <i class="ml-1 fas fa-circle-notch fa-spin"></i>
    </div>
</div>


<div class="ak-full-modal bg-gray "  *ngIf="showChangePass">
    <div class="header">
        <div class="d-flex bd-highlight pr-2 pl-2 pb-2">
            <div *ngIf="!sizeMenuLeft" class=" pt-1">
                <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
            </div>
            <div class="  pr-0 pl-md-3 flex-grow-1 bd-highlight">
                <!-- <h4 class=" pt-3" [innerHTML]="'@i18n-change-password' | i18n"></h4> -->
            </div>
            <div class=" bd-highlight pt-2 ">
                <a class=" text-dark" href="javascript:void(0)" (click)="setShowChangePassword(false)">
                    <ng-container *ngIf="env.theme.images !== 'PERCAPITA'">
                        <img class="size-img close" src="assets/public/images/close.svg">
                    </ng-container>
                    <ng-container *ngIf="env.theme.images === 'PERCAPITA'">
                        
                        <img class="size-img close" src="assets/public/images/close_white.svg">
                    </ng-container>
                </a>
            </div>
        </div>
    </div>
    <div class="body">
        <app-change-user-password></app-change-user-password>
    </div>
</div>




<div *ngIf="!isConnected" [ngClass]="{'toast-danger': status != 'ONLINE','toast-success': status == 'ONLINE'}" class="ak-alert toast botton-left    {{!isConnected?'animate__animated animate__fadeIn animate__faster ':'' }}">

    <div class="toast-body">
        <span *ngIf="status == 'ONLINE'">
            Conexión restablecida
        </span>
        <span *ngIf="status == 'OFFLINE'">
            Se está intentando establecer la conexión...<br> Revise su conexión a la red
        </span>
    </div>
</div>

<!-- <ngx-doc-viewer 
    [viewerUrl]="'https://docs.google.com/gview?url=%URL%&embedded=true'" 
    [url]="'https://services.dev.arn.akeela.co:8443/archive/downloadFile?idArchive=149&contentDisposition=inline'"
    style="width:100%;height:100vh;">
</ngx-doc-viewer> 


<ng-container *ngIf="env?.auth?.inactivity?.active === true">
    <app-keepalive [timeinactivity]="timeinactivity" *ngIf="reloadkeepalive">
    </app-keepalive>
</ng-container>-->


<!-- *SECCIÓN DE TEMPLATE TEMPLATE AUXILIAR* -->


<!--

Modal nuevo
<div class=" capa-menu-2"> </div>
<div class="ak-full-modal-3 " style="height: 100vh!important;">
    <div class="container">
        <div class="w-100 bg-white mt-3 body p-3" style="overflow:auto">
            <div>
                <h1>Hola mundo</h1>
            </div>
        </div>
    </div>

</div>

 -->


<app-validate-phone *ngIf="activeValidateTlf" [selected]="activeValidateTlf" [config]="dataValidateTlf" (close)="closeValidateTlf($event)" (change)="setValidateTlf($event)"></app-validate-phone>
<app-validate-email *ngIf="activeValidateEmail" [selected]="activeValidateEmail" [config]="dataValidateEmail" (close)="closeValidateEmail($event)" (change)="setValidateEmail($event)"></app-validate-email>
<app-suggestion *ngIf="activeSuggestion" [selected]="activeSuggestion" (close)="closeSuggestion($event)" [config]="detailSuggestion" ></app-suggestion>




<ng-container *ngTemplateOutlet="templates['menuLeftBox'];"></ng-container>

<ng-container *ngTemplateOutlet="templates['mainContentApp'];"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainContentModalBotton'];" style="font-weight: bold"></ng-container>
<ng-container *ngTemplateOutlet="templates['responsesWindowContent'];"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainContentModalBottonMeet']; " style="font-weight: bold"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainContentModalApp']; " style="font-weight: bold"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainContentModalCenter'];"></ng-container>

<!-- WINDOW REQUIREMENTS -->
<ng-container *ngTemplateOutlet="templateRequirements['requirementsWindowContent'];"></ng-container>
<ng-container *ngTemplateOutlet="templateRequirements['requirementsOtherWindowContent'];"></ng-container>

<div *ngIf="upload.visor" class="animated" [class.zoomOut]="!upload.visor">

    <ng-container *ngIf="env?.visor==='alternative'">
        <akeela-visor-google [filename]="upload.visor_params.name" [filedate]="upload.visor_params.date" [visorparams]="upload.visor_params" [back]="this.upload._back" [next]="this.upload._next" (close)="window_close()" (eventaction)="action($event)">
        </akeela-visor-google>
    </ng-container>
    <ng-container *ngIf="env?.visor!=='alternative'">
        <akeela-visor [filename]="upload.visor_params.name" [filedate]="upload.visor_params.date" [visorparams]="upload.visor_params" [back]="this.upload._back" [next]="this.upload._next" (close)="window_close()" (eventaction)="action($event)">
        </akeela-visor>
    </ng-container>
</div>



<!-- aqui-->

<!-- 
    <div class="capa-menu" *ngIf="showSidebarLeft && !showIdentityVerification" (click)="toggleMenu()"> </div>

<sd-akeela-template>
    <ng-template sd-akeela-content componentName="menuBody">

        <div class="menu-body mb-3 mt-3 font-weight-normal " style="font-size: 1px">

            <ng-container *ngFor="let stage of workflowStatusMenu ; let first = first; let i = index">
                <ng-container *ngFor="let step of stage?.howToSteps ; let first2 = first; let i2 = index">
                    <ng-container *ngFor="let section of step?.sections ; let first3 = first; let i3 = index">
                        <div (click)="redirectSection(workflowCodeActive,stage?.alternateName,step?.alternateName);optionActive = section.alternateName" class="principal dd-link cursor-pounter" [ngClass]="{'active': section?.alternateName == optionActive}">
                            <a href="javascript:void(0) " disabled="false">
                                <span> {{section?.alternateName | i18n}} </span>
                            </a>
                        </div>
                    </ng-container>
                </ng-container>

            </ng-container>

            <div (click)="redirectSection('DOC_SUPPORT','DOC_SUPPORT','UPLOAD_REQUIREMENTS',optionActive = 'recaudos')" class="principal dd-link cursor-pounter" [ngClass]="{'active': 'recaudos' == optionActive}">
                <a href="javascript:void(0) " disabled="false">
                    <span> {{'Recaudos' | i18n}} </span>
                </a>
            </div>

        </div>
    </ng-template>
    <ng-template sd-akeela-content componentName="menuFooter">
    </ng-template>
</sd-akeela-template>

<div>
    <akeela-sidebarLeft [notloading]="true" [class.d-none]="!showSidebarLeft" (response)="eventMenu($event)">
    </akeela-sidebarLeft>
</div>
-->


<ng-container *ngIf="activeRedirects">
    <div class="text-center mt-4">
        <img src="./assets/public/images/loading.gif" alt="">
    </div>
</ng-container>
<div class="" *ngIf="!activeRedirects">
    <app-header></app-header>

    <div class="container">
        <!--
   <div *ngIf="env?.theme?.images == 'AKEELA'" class=" bg-light  animate__animated animate__fadeInDown animate__delay-1s ak-font-roboto  mb-3 mt-3 alert alert-secondary w-100 text-center text-secondary p-1" style=" background: #fffbf0;">
                    Este es un sitio demostrativo y los trámites no serán procesados.
             </div>


-->






        <div *ngIf="localStorage.getItem('objToken')" class=" bg-light alert alert-secondary mt-3">

            <div class="row">
                <div class="col-12 col-md-10">
                    Su solicitud de
                    <span class="font-weight-bold" [innerHTML]=" localStorage.getItem('objTokenPlan') | i18n"></span> ha sido almacenada temporalmente. Ingrese o cree su cuenta de usuario para continuar.

                </div>

                <div class="col-12 mt-1 mt-md-2   col-md-2 text-right">
                    <a class=" text-primary" href="javascript:void(0)" (click)="deleteToken()">
                        Descartar
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div id="id-pleft">


        <app-subheader></app-subheader>

        <router-outlet></router-outlet>

        <app-footer></app-footer>
    </div>


</div>